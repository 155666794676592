import React , {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import LogoComponent from './LogoComponent'
import CopyRight from './CopyRight'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DEFAULTS } from '../../Constants/Constants'
import { firebaseApp, analytics } from "../../lib/firebase";
import { logEvent } from "firebase/analytics";
const theme = createTheme();



export default function AboutUs() {

  const navigate = useNavigate();
  
useEffect(() => {
    logEvent(analytics, 'About Us', {
content_type: 'page',
content_id: 'about-us'
});
}, []);
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://ikeocean.com/wp-content/uploads/2020/05/containers-1.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <LogoComponent /> */}
           <Typography component="h1" variant="h5">About Us</Typography>

           <Typography variant="body1">About Us Content</Typography>

              {/* <CopyRight sx={{ mt: 5 }} /> */}
          </Box>
          
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}