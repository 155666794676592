export const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };


  export const getDateTime = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateTimeOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
  
    const dateTimeString: string = dateObj.toLocaleDateString(undefined, dateTimeOptions);
    console.log(dateTimeString);
    return dateTimeString;
  };

  export const sortByDate = (records: [],sortField : any) => {
  const recordsSorted = records.sort((a: any, b: any) => {
    let da: any = new Date(b[sortField])
    let   db: any = new Date(a[sortField])
    return da - db;
  })
  return recordsSorted
}

export const getPackageName = (packagename:string) =>{
  return packagename!= null ? (packagename.replace(/_/g, ' ')).toUpperCase() : '-'
}