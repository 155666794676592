import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Alert, RadioGroup, FormControlLabel, Radio,LinearProgress, TextField, Button, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useFormik } from 'formik';
import Modal from '@mui/material/Modal';
import * as Yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { HostedForm, HostedFormDispatchDataResponse } from 'react-acceptjs';
import { useAcceptJs } from 'react-acceptjs';
import {
    formatExpirationDate
} from '../../../../util/CardUtils'
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useSelector } from 'react-redux'
import { updateOrder,
  getOrder,
  selectShipmentOrder } from '../../../../features/shipment/shipmentSlice'
  import { DEFAULTS } from '../../../../Constants/Constants'
type shipmentOrderType = {
  [key: string]: any;
}

const authDataProd = { //prod
  apiLoginID: '5shgWG8V5j',
  clientKey: '4Xqe2Ar9VCp6cZZy56gfZ7dr5r555KZM7M4BXB32Ta58AgFDdeSbJ9q5cwV2QK84',
  //clientKey: '28u2H8B4VanT3az3',
};

const authDataTest = {  
  apiLoginID: '2d4Bq8L4',
  clientKey: '387abB5JbfVP975s6x9qZt4EsVxPS7Nfvp8us7qTWSeWtCVw9Tgk9sQNNmAp34s5',
  //clientKey: '4C6js4w5E228E2mf',
};


type BasicCardInfo = {
  cardNumber: string;
  cardCode: string;
  month: string;
  year: string;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function PaymentInfo() {
    const [SHIPMENT, setShipment] = useState<{[key: string]: any}>({});
    let SHIPMENTORDER : shipmentOrderType = JSON.parse(JSON.stringify(useAppSelector(selectShipmentOrder)))
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [response, setResponse] = React.useState<string>('');
    const shipmentPayOrder=SHIPMENTORDER.shipmentOrder
    console.log("shipmentOrderpayment >>>>", SHIPMENTORDER)
    console.log("shipmentOrder >>>>", shipmentPayOrder)

    const shippingAmount = Number(SHIPMENTORDER?.shipmentOrder?.rate.shippingAmount.amount) + Number(SHIPMENTORDER?.shipmentOrder?.rate.insuranceAmount.amount)
    //const FinalAmount = (shippingAmount + (shippingAmount* DEFAULTS.RATEPERCENTAGE)) + (shippingAmount + (shippingAmount* DEFAULTS.RATEPERCENTAGE)) * DEFAULTS.GEORGIATAX
    const FinalAmount = shippingAmount +  (shippingAmount *  DEFAULTS.GEORGIATAX)

    const startPayment = async () => {
      
      handleOpen()
      const shipmentOrder : any = SHIPMENTORDER.shipmentOrder
      console.log("shipmentOrder ----s", shipmentOrder)
      const order: any = {shipments:[]}
      shipmentOrder.shipments.service_code = SHIPMENTORDER?.shipmentOrder?.rate.service_code ;// "ups_3_day_select"
      shipmentOrder.shipments.external_shipment_id=SHIPMENTORDER.shipmentOrder.order_id
      shipmentOrder.shipments.confirmation = "none"
      shipmentOrder.shipments.advanced_options = {}
      shipmentOrder.shipments.insurance_provider= "none"
      shipmentOrder.shipments.tags= []
      order.shipments.push(shipmentOrder.shipments)
      console.log("final order", order)
      const orderPayLoad = {
        rate :  SHIPMENTORDER?.shipmentOrder?.rate.rateId,
        shipmentId:SHIPMENTORDER?.shipmentOrder?.order_id

      }

      const OrderResponse = await createOrder(order)
      console.log("OrderResponse",OrderResponse)
      handleClose()
      navigate('/portal/shipment/complete/'+OrderResponse.shipments[0].external_shipment_id);
  
        
    }
    
    let navigate = useNavigate();
    const createOrder = async (formbody: any) => {
      try {
        const response = await axios.post(DEFAULTS.API_URL+'order', formbody);
        return response.data;
      } catch (error: any) {
        // Handle the error here, or you can rethrow the error to be caught elsewhere
        throw new Error("Error creating order: " + error.message);
      }
    };

    
    return (
        <Container>
            <Typography variant='h5' display={'inline'} align='left'>Payment</Typography>
            <Typography variant='body1' display={'inline'} align='right'>(Step 5 of 5)</Typography>
            <Box>

                <Card>
                    <CardContent>
                        <Alert style={{marginTop:20, marginBottom:20}}>You will be charged USD {Number(FinalAmount).toFixed(2)} on your wallet in order to confirm your order.</Alert>
                        
                <Button
                    variant="contained"
                    onClick={() => startPayment()}>
                    Pay and confirm order
                </Button>


                {/* <Button
                variant="contained"
                style={{marginLeft:10 }}
                type="submit"
              >
                Next
              </Button> */}
              </CardContent>
                </Card>
 
            </Box>
            <Modal
  open={open}
  onClose={(event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  }}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Payment in progress
    </Typography>
    <LinearProgress color="secondary" />
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Do not close your browser or click the browser back button
    </Typography>
  </Box>
</Modal>
        </Container>
    );
}


export default PaymentInfo;