import React, {useState, useEffect} from 'react';
import {Container, Card, CardContent, Alert, RadioGroup, FormControlLabel, Radio, Button, Typography, Box} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getDate } from '../../../../util/DisplayUtils';
import moment from 'moment';
import { useAppSelector} from '../../../../hooks'
import { RootState, useAppDispatch } from '../../../../store'
import { useSelector } from 'react-redux'

import { updateOrder,
  getOrder,
  selectShipmentOrder } from '../../../../features/shipment/shipmentSlice'
  import { DEFAULTS } from '../../../../Constants/Constants'
type shipmentOrderType = {
  [key: string]: any;
}

type customerProfileType = {
  [key: string]: any;
}

function AddOns() {
  //const [shipmentOrder, setShipmentOrder] = useState<{[key: string]: any}>({});
  const CUSTOMERPROFILE: customerProfileType = useSelector((state: RootState) => state.profile);
  let SHIPMENTORDER : shipmentOrderType = JSON.parse(JSON.stringify(useAppSelector(selectShipmentOrder)))
    const dispatch = useAppDispatch();
    console.log("addons",SHIPMENTORDER)
    const shipmentOrder=SHIPMENTORDER.shipmentOrder
    const token = localStorage.getItem('user-token');

const shipping  = Number(shipmentOrder.rate.shippingAmount.amount).toFixed(2)
const insurance =  Number(shipmentOrder.rate.insuranceAmount.amount).toFixed(2) // + (DEFAULTS.INSURANCERATE * shipmentOrder.rate.insurance_amount.amount)).toFixed(2)
const others = (
  Number(shipmentOrder.rate.otherAmount.amount) +
  DEFAULTS.RATEPERCENTAGE * Number(shipmentOrder.rate.otherAmount.amount)
).toFixed(2);

const orderTotal = (
  Number(shipping) +
  Number(insurance) +
  Number(others)
).toFixed(2);

const shippingtTax = Number(DEFAULTS.GEORGIATAX * Number(orderTotal)).toFixed(2);
const orderTotalTax = (Number(orderTotal) + Number(shippingtTax)).toFixed(2);


  let navigate = useNavigate();

  const createShipmentOrder = async () => {
    const payload = {
      "shipmentOrder" : SHIPMENTORDER.shipmentOrder
    }
    await axios.post(DEFAULTS.API_URL+'createOrder', payload,
    {headers: {
      'Authorization': `Bearer ${token}`
    }})
    .then(
      response => {
        
        if (response.data.shipmentOrder.order_id)
        {
          console.log("create Order", response.data)
          dispatch(updateOrder(response.data))

          navigate('/portal/shipment/payment')
        }
      }
    )
      
  }


  return (
    <Container>
      <Typography variant='h5' align="left" display={'inline'}>Review</Typography>
      <Typography variant='body1' align="right" display={'inline'}>(Step 4 of 5)</Typography>
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={4} style={{marginBottom:10}}>
              <Grid item xs={12} md={4}>
                Ship To
                {shipmentOrder?.shipments?.ship_to ? (
                  <div> 
                    <ul style={{listStyle:'none', paddingLeft:0}}>
                      <li>{shipmentOrder?.shipments.ship_to.name}</li>
                      <li>{shipmentOrder?.shipments.ship_to.address_line1}</li>
                      <li>{shipmentOrder?.shipments.ship_to.city_locality}</li>
                      <li>{shipmentOrder?.shipments.ship_to.state_province} {shipmentOrder?.shipments.ship_to.postal_code} {shipmentOrder?.shipments.ship_to.country_code}</li>
                      <li>{shipmentOrder?.shipments.ship_to.phone}</li>
                    </ul>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} md={4}>
                Ship From
                {shipmentOrder?.shipments?.ship_from ? (
                  <div>
                    <ul style={{listStyle:'none', paddingLeft:0}}>
                      <li>{shipmentOrder?.shipments.ship_from.name}</li>
                      <li>{shipmentOrder?.shipments.ship_from.address_line1}</li>
                      <li>{shipmentOrder?.shipments.ship_from.city_locality}</li>
                      <li>{shipmentOrder?.shipments.ship_from.state_province} {shipmentOrder?.shipments.ship_from.postal_code} {shipmentOrder?.shipments.ship_from.country_code}</li>
                      <li>{shipmentOrder?.shipments.ship_from.phone}</li>
                    </ul>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} md={4}>
                Return To
                {shipmentOrder?.shipments?.return_to ? (
                  <div>
                    <ul style={{listStyle:'none', paddingLeft:0}}>
                      <li>{shipmentOrder?.shipments.return_to.name}</li>
                      <li>{shipmentOrder?.shipments.return_to.address_line1}</li>
                      <li>{shipmentOrder?.shipments.return_to.city_locality}</li>
                      <li>{shipmentOrder?.shipments.return_to.state_province} {shipmentOrder?.shipments.return_to.postal_code} {shipmentOrder?.shipments.return_to.country_code}</li>
                      <li>{shipmentOrder?.shipments.return_to.phone}</li>
                    </ul>
                  </div>
                ) : null}
              </Grid>
              </Grid>
              <Divider/>
              <Grid container spacing={4} style={{marginTop:10, marginBottom:10}}>
              <Grid item xs={12} md={6} >
                Shipment Details
                {shipmentOrder?.rate ? (
                  <>
                   <Table size="small">
                    <TableRow>
                      <TableCell>Carrier Name </TableCell>
                      <TableCell>: {shipmentOrder.rate.carrierFriendlyName} </TableCell></TableRow>
                      <TableRow><TableCell>Delivery Days</TableCell><TableCell>:  {shipmentOrder.rate.deliveryDays}</TableCell></TableRow>
                     <TableRow><TableCell>Ship Date </TableCell><TableCell>:  {getDate(shipmentOrder.rate.shipDate)}</TableCell></TableRow>
                    <TableRow><TableCell>Estimated Delivery Date </TableCell><TableCell>:  {getDate(shipmentOrder.rate.estimatedDeliveryDate)} </TableCell></TableRow>
                    <TableRow><TableCell>Service Type</TableCell><TableCell>:  {shipmentOrder.rate.serviceType} </TableCell></TableRow>
                    <TableRow><TableCell>Guranteed Service</TableCell><TableCell>:  {shipmentOrder.rate.guaranteedService? 'Yes': 'No'}</TableCell></TableRow>
                    <TableRow><TableCell>Trackable</TableCell><TableCell>:  {shipmentOrder.rate.trackable? 'Yes': 'No'}</TableCell></TableRow>
                    </Table>



</>

                ): null}
              </Grid>
              <Grid item xs={12} md={6}>
                Package Details
              
                {shipmentOrder?.shipments?.packages ? (
                  <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Dimensions (L X W X H) </TableCell>
                      <TableCell>Weight</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>   
                    {shipmentOrder.shipments.packages.map((shipPackage: any, index: any) => (
                       <TableRow>
                       <TableCell>{shipPackage.dimensions.length} X {shipPackage.dimensions.width} x {shipPackage.dimensions.height} {shipPackage.dimensions.unit.toUpperCase()}</TableCell>                     
                      <TableCell>{shipPackage.weight.value}{shipPackage.weight.unit.toUpperCase()} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  </Table>
                ) : null}

    
            </Grid>
            </Grid>
            <Divider/>
            <Grid container spacing={4} style={{marginTop:10, marginBottom:10}}>
              <Grid item xs={12} md={6} >
            
                  { (shipmentOrder.rate.warningMessages.length > 0) ? (
                    
                    <Alert color='warning'>
                    {shipmentOrder.rate.warningMessages.map((warning: any, index: any) => (
                     <p>{warning}</p>
                    ))}
                    </Alert>
                  ) : null}
                  </Grid>

<Grid item xs={12} md={6} >
Shipping  : $ {shipping}  <br />
Insurance : $ {insurance}   <br />
Others    : $ {others}  <br />
Total     : $ {orderTotal}  <br />
Georgia Tax : $ {shippingtTax} <br />
Grand Total :  $ {orderTotalTax} <br />
  </Grid>
  </Grid>
  {(parseFloat(CUSTOMERPROFILE?.value?.wallet || '0') <= Number(orderTotalTax)) &&
  <Alert severity='error' style={{ marginTop: 20, marginBottom: 20 }} >Your wallet balance is  ${Number(CUSTOMERPROFILE?.value?.wallet).toFixed(2)} and may not be sufficient to complete the order. Please recharge</Alert>
  }
            <Grid container spacing={4} style={{marginTop:10, marginBottom:10}}>
              <Grid item xs={12} md={6} >
            <Button variant="contained" onClick={() => navigate('/portal/shipment/courier-info')}>
          Previous
        </Button>
        <Button variant="contained"
         onClick={createShipmentOrder} 
         style={{marginLeft:10}}
         disabled={(parseFloat(CUSTOMERPROFILE?.value?.wallet || '0') <= Number(orderTotalTax))? true:false}
         >
          Next
        </Button>
        </Grid>
        </Grid>
          </CardContent>
        </Card>

      </Box>
    </Container>
  );
}

export default AddOns;
