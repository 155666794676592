import React, {useState, useEffect} from 'react';
import {Container, Button, Card, CardContent,Alert,Typography, Box, RadioGroup, Radio, CircularProgress} from '@mui/material';
import { DataGrid, GridRowId,GridRowClassNameParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from "@mui/material";
import Divider from '@mui/material/Divider';
import axios from 'axios';
import moment from 'moment';
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useSelector } from 'react-redux'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import { styled } from '@mui/material/styles';
import { updateOrder,
  getOrder,
  selectShipmentOrder } from '../../../../features/shipment/shipmentSlice'
  import { DEFAULTS } from '../../../../Constants/Constants'
  import { getPackageName } from '../../../../util/DisplayUtils';
  import '../../../css/portal.css'; // Import your styles file
type shipmentOrderType = {
  [key: string]: any;
}



const myTheme = createTheme({
  components: {
    //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
    Button:{
      
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "&.Mui-selected": {
            backgroundColor: "rgb(253, 237, 237)"
          }
        }
      }
    }
  }
});

interface MyType {
  serviceCode: string;
  serviceType: string;
  carrier_delivery_days: number;
  errorMessages: string[];
  shippingAmount: {
    amount: number;
    // Other properties related to shipping_amount
  },
  insuranceAmount: {
    amount: number;
    // Other properties related to shipping_amount
  };
  // Other properties
}

interface MyTypeOld {
  service_code: string;
  service_type: string;
  carrier_delivery_days: number;
  error_messages: string[];
  shipping_amount: {
    amount: number;
    // Other properties related to shipping_amount
  },
  insurance_amount: {
    amount: number;
    // Other properties related to shipping_amount
  };
  // Other properties
}


interface RateType {
  service_code: string;
  mark_up: string;
  // Other properties related to rates
}

/* const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
})); */

interface HtmlTooltipProps extends TooltipProps {
  content: string[];
}

interface Rate {
  [key: string]: any
}

const HtmlTooltip = styled(({ className, content, ...props }: HtmlTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} title={content.map((item, index) => <div key={index}>{item}</div>)} />
))(({ theme }) => ({
  [`& .${tooltipClasses.popper}`]: { // Access the popper class from @mui/material/Tooltip
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


function CourierInfo() {
  let SHIPMENTORDER : shipmentOrderType = JSON.parse(JSON.stringify(useAppSelector(selectShipmentOrder)))
  const dispatch = useAppDispatch();

  console.log("SHIPMENTORDER", SHIPMENTORDER)
  //const [shipRates, setShipRates] = useState([])
  const [shipRates, setShipRates] = useState<Rate[]>([]);
  //const [filteredRates, setFilteredRates] = useState([]) //useState<{[key: string]: any}>({});
  const [filteredRates, setFilteredRates] = useState<Rate[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [shipRatesReponse, setShipRatesResponse] =  useState<{[key: string]: any}>({});
  const [shipRate, setRate] = useState('')
  const [dataFetched, setDataFetched] = useState(true)
  const [carrierCode, setCarrier] = useState('')
  const [selectedRow, setSelectedRow] = useState<GridRowId | null>(null);
  let navigate = useNavigate();

  const formbody: any = {
    "rate_options": {
      "carrier_ids": DEFAULTS.CARRIERS
    },
    "shipment": {}
  };
  const TotalRate =(shippingAmt: any, insuranceAmt: any) =>{
    console.log(" -- ---", shippingAmt, insuranceAmt)
   //const shipping = rateRow.shipping_amount.amount + (rateRow.shipping_amount.amount * DEFAULTS.RATEPERCENTAGE).toFixed(2)
   //const insurance = rateRow.insurance_amount.amount + (rateRow.insurance_amount.amount * DEFAULTS.RATEPERCENTAGE).toFixed(2)
  // const finalAmt = Number(shippingAmt) + Number(insuranceAmt) + (Number(shippingAmt) + Number(insuranceAmt)) * DEFAULTS.RATEPERCENTAGE
   const finalAmt = Number(shippingAmt) + Number(insuranceAmt) 
   console.log(" -- ---", shippingAmt, insuranceAmt, finalAmt, DEFAULTS.RATEPERCENTAGE)
   return finalAmt.toFixed(2)
  }

const clickNext = () => {

const selectedRate = shipRates.filter(function (rates1, index) {
return rates1["rateId"] == shipRate;
});

console.log("rates>>>w>", selectedRate, shipRates)
SHIPMENTORDER.shipmentOrder.rate = selectedRate[0];
SHIPMENTORDER.shipmentOrder.shipments.service_code = SHIPMENTORDER.shipmentOrder.rate.service_code

console.log("shipmentData.shipments.packages", SHIPMENTORDER)
dispatch(updateOrder( SHIPMENTORDER))
navigate('/portal/shipment/add-ons');

}








 
  useEffect(() => {
    // Update the document title using the browser API
    formbody.shipment= SHIPMENTORDER.shipmentOrder.shipments
    console.log("formbody", formbody)

    const expensesListResp = async () => {
      setLoading(true)
      await axios.post(DEFAULTS.API_URL + 'rates', formbody)
      .then(
        response => {


          const myArray: MyType[] = response.data.rateResponse.rates
          const rates: RateType[] = DEFAULTS.MARKUP


          const filteredAndModifiedArray = myArray
.filter(item => item.errorMessages.length === 0)
.map(item => {
 const rate = rates.find(rateItem => rateItem.service_code === item.serviceCode);

 if (rate) {
   const modifiedAmount: any = Number(item.shippingAmount.amount) * Number(rate.mark_up) //(1 + rate.mark_up / 100);
   const modifiedInsurance: any = Number(item.insuranceAmount.amount) * Number(DEFAULTS.INSURANCERATE)
   console.log("modified rate", modifiedAmount, modifiedInsurance,)
   return {
     ...item,
     shipping_amount: {
       amount: modifiedAmount,
       // Other properties related to shipping_amount
     },
     insurance_amount: {
      amount: modifiedInsurance,
      // Other properties related to shipping_amount
    },
   };
 }

 return item; // If no rate is found, leave the item unchanged
});

// Step 2: Sort the filtered and modified array by service_type ascending, carrier_delivery_days ascending
const sortedArray = filteredAndModifiedArray.sort((a, b) => {
// First, sort by service_type in ascending order
const serviceTypeComparison = a.serviceType.localeCompare(b.serviceType);

// If service_type is the same, then sort by carrier_delivery_days in ascending order
if (serviceTypeComparison === 0) {
 return a.carrier_delivery_days - b.carrier_delivery_days;
}

return serviceTypeComparison;
});
       console.log("filtered", filteredAndModifiedArray)


          //setShipRates(response.data.rate_response.rates)
          setFilteredRates(filteredAndModifiedArray)
          setShipRates(filteredAndModifiedArray)
          setShipRatesResponse(response.data.rateResponse)
          setDataFetched(false)
          setLoading(false)
  
        })
    
        
    }
    expensesListResp();
    console.log("radiochecked", shipRates[0])
    //setChecked(shipRates[0].rate_id);
  },[]);


  const getRowClassName = (params: any) => {
    console.log("getRowClassName",params.id, selectedRow)
    return params.id === selectedRow ? 'selected-row' : '';
  };
  
  const columns = [
    //{ field: "rate_id", headerName: "ID", width: 90 },
    // {
    //   field: "rate_id",
    //   headerName: "",
    //   width: 50,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <Radio 
    //     checked={radioChecked === params.rate_id}
    //     //checked={cellValues.id === radioChecked}
    //     //onChange={setSelectionModel(params.rate_id)}
    //     name="radio-buttons"
    //     value={params.rate_id} />
    //   )
    // },
    {
      field: "carrierCode",
      headerName: "Code",
      width: 100,
      valueGetter: (params: any) => {
      return params.row.carrierCode.toUpperCase()
      }
      // renderCell: (params: any) => (
      //   <img src="https://assets.easyship.com/app/courier-logos/usps-mini.svg" />
      // )
    },
    {
      field: "serviceType",
      headerName: "Service",
      width: 300,
      sortable: true,
      filter:false
    },
    {
      field: "deliveryDays",
      headerName: "Days",
      width: 100,
      sortable: true,
      filter:false
    },
    {
      field: "packageType",
      headerName: "Type",
      valueGetter: (params: any) => {
        //console.log("params ==", params.row.shipping_amount.amount);
        //params.rows.shipping_amount.amount
        return getPackageName(params.row.packageType)
            },
      width: 200,
      sortable: true,
      filter:false
    },
    {
      field: "shippingAmount",
      headerName: "Charges",
      valueGetter: (params: any) => {
        console.log("params ==", params.row.shippingAmount.amount, params.row.insuranceAmount.amount);
        //params.rows.shipping_amount.amount
        const amt =TotalRate(params.row.shippingAmount.amount, params.row.insuranceAmount.amount)
        return amt
      },
      width: 100,
      sortable: true,
      filter:false
    },

    {
      field: "estimatedDeliveryDate",
      headerName: "EDD",
      width: 250,
      sortable: true,
      filter:false,
      valueGetter: (params: any) => {
        //console.log("params ==", params.row.shipping_amount.amount);
        //params.rows.shipping_amount.amount
        return moment(params.row.estimatedDeliveryDate).format('MMMM Do YYYY, h:mm:ss a')
  
      }
      
    },
    {field:"warningMessages",
    headerName: "",
    width: 10,
    sortable: false,
    filter:false,
      renderCell: (params: any) => (    (params.row.warningMessages.length >0 ) && <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">Tooltip with HTML</Typography>
          </React.Fragment>
        }
        content={params.row.warningMessage} arrow
      >
        <InfoIcon/>
      </HtmlTooltip> )
  }
    

  ];

const handleRowClick = (row: any, params: any) =>{
  console.log("row",row.id, row.row.serviceCode)
  
  setCarrier(row.row.serviceCode)
  setRate(row.row.rateId)
  setSelectedRow(row.id);
}



    return (
      <ThemeProvider theme={myTheme}>
<Container>
<Typography variant='h5' align="left" display={'inline'}>Select Courier</Typography>
      <Typography variant='body1' align="right" display={'inline'}>(Step 3 of 5)</Typography>
    <Box>
              
<Card><CardContent style={{minHeight:100}}>
 {loading && (<p style={{minHeight:100, textAlign:'center'}}><CircularProgress/><br/> Loading best rates for you</p>)} 
{shipRatesReponse?.rates?.length > 0 && 
  <DataGrid
  rows={filteredRates}
  columns={columns}
  autoHeight
  getRowId={(row: any) => row.rateId}
  loading={dataFetched}
  onRowClick={handleRowClick}
  style={{ marginBottom: 10 }}
  //checkboxSelection
  // getRowClassName={(params: GridRowClassNameParams<any>) => {
  //   if (params.id === selectedRow) {
  //     console.log("row.rate_id", params.id, selectedRow);
  //     return 'selected-row';
  //   }
  //   return ''; // Return an empty string for non-selected rows
  // }}
/>
 
}


{shipRatesReponse?.errors?.length > 0 &&
<Alert severity="error" style={{marginTop:10, marginBottom:10}}>{shipRatesReponse?.errors[0].message}</Alert>
}
      <Button
                  type="button"
                  variant="contained"
                  onClick={()=>{navigate('/portal/shipment/shipment-info')}}
                  >Previous</Button>
      <Button 
        style={{marginLeft:10}}
        variant="contained"
        disabled={carrierCode === ''}
        onClick= {clickNext}>
        Next
       </Button>
</CardContent>
</Card>
</Box>
</Container>
</ThemeProvider>
    );
  }

  

  export default CourierInfo;