import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Alert,
  FormHelperText,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Card,
  CardContent,
  Typography,
  FormControl,
  Switch,
  Container,
  Autocomplete

} from '@mui/material';
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from '../../../../hooks'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../store'
import {
  updateOrder,
  getOrder,
  selectShipmentOrder
} from '../../../../features/shipment/shipmentSlice'
import {
  updateProfile,
  selectProfile, fetchProfile
} from '../../../../features/profile/profileSlice'
import { DEFAULTS } from '../../../../Constants/Constants'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
type customerProfileType = {
  [key: string]: any;
}

type shipmentOrderType = {
  [key: string]: any;
}


function Shipment() {
  let navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [sameAddress, setSameAddress] = useState(false);
  const [returnToIndicator, setReturnToIndicator] = useState(false);
  const [shipToIndicator, setShipToIndicator] = useState(false);
  const [shipFromIndicator, setShipFromIndicator] = useState(false);
  const CUSTOMERPROFILE: customerProfileType = useSelector((state: RootState) => state.profile);
  let SHIPMENTORDER: shipmentOrderType = JSON.parse(JSON.stringify(useAppSelector(selectShipmentOrder)))
  const dispatch = useAppDispatch();
  console.log("SHIPMENTORDER", SHIPMENTORDER)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleReturnToIndicator = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReturnToIndicator(event.target.checked);
  };

  const handleShipToIndicator = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShipToIndicator(event.target.checked);
  };

  const handleShipFromIndicator = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShipFromIndicator(event.target.checked);
  };

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameAddress(event.target.checked);
    console.log("check",event.target.checked)
    if (event.target.checked) {

    }

  };

  const splitAddressComponents = (addressComponents: any) => {
    const result: { [key: string]: string } = {}; // Specify the type for 'result'
  
    addressComponents.forEach((component: any) => {
      const types: string[] = component.types; // Specify the type for 'types'
      types.forEach((type: string) => {
        if (!result[type]) {
          result[type] = component.short_name;
        }
      });
    });
  
    return result;
  };
  const formik = useFormik({
    initialValues: {
      shipments:
      {
        validate_address: "no_validation",
        service_code: "",
        external_shipment_id: "",
        ship_to: {
          name: "",
          company_name: "",
          phone: "",
          apartment:"",
          address_line1: "",
          city_locality: "",
          state_province: "",
          postal_code: "",
          country_code: "US",
          address_residential_indicator: "no",
          location:""
        },
        ship_from: {
          company_name: "",
          name: "",
          phone: "",
          apartment:"",
          address_line1: "",
          city_locality: "",
          state_province: "",
          postal_code: "",
          country_code: "US",
          address_residential_indicator: "no",
          location:""
        },
        return_to: {
          company_name: "",
          name: "",
          phone: "",
          apartment:"",
          address_line1: "",
          city_locality: "",
          state_province: "",
          postal_code: "",
          country_code: "US",
          address_residential_indicator: "no",
          location:""
        },
        confirmation: "none",
        advanced_options: {},
        insurance_provider: "shipsurance",
        tags: [],
        packages: []
      }
    },
    validationSchema: Yup.object().shape({
      shipments: Yup.object().shape({
        ship_to: Yup.object().shape({
          name: Yup.string()
          .min(8,'Name should be atlest 8 characters')
            .required('Name is required'),
            phone: Yup.string()
            .min(8)
            .required('Phone is required'),
          address_line1: Yup.string()
            .min(8)
            .required('Address Line 1  is required'),
          city_locality: Yup.string()
            .required('City is required'),
          state_province: Yup.string()
            .required('State is required'),
          country_code: Yup.string()
            .required('Country is required'),
          postal_code: Yup.string()
            .required('Zip Code is required'),
            location: Yup.string()
            .required('Ship from address is required'),

        }),
        ship_from: Yup.object().shape({
          name: Yup.string()
           .min(8,'Name should be atlest 8 characters')
            .required('Name is required'),
          phone: Yup.string()
            .min(8)
            .required('Phone is required'),
          address_line1: Yup.string()
            .min(8)
            .required('Address Line 1 is required'),
          city_locality: Yup.string()
            .required('City is required'),
          state_province: Yup.string()
            .required('State is required'),
          country_code: Yup.string()
            .required('Country is required'),
          postal_code: Yup.string()
            .required('Zip Code is required'),
            location: Yup.string()
            .required('Ship from address is required'),
        }),
        return_to: Yup.object().shape({
          name: Yup.string()
          .min(8,'Name should be atlest 8 characters')
          .required('Name is required'),
          phone: Yup.string()
            .min(8)
            .required('Phone is required'),
        address_line1: Yup.string()
          .min(8)
          .required('Address Line 1 is required'),
        city_locality: Yup.string()
          .required('City is required'),
        state_province: Yup.string()
          .required('State is required'),
        country_code: Yup.string()
          .required('Country is required'),
        postal_code: Yup.string()
          .required('Zip Code is required'),
        company_name: Yup.string()
          .required('Company is required'),
          location: Yup.string()
          .required('Return address is required'),
        })
      })
    }),

    onSubmit: (values: any) => {
      //dispatch(updateOrder({shipmentOrder:{shipments:values.shipments}}))
      console.log("shipments formik", values.shipments)
      SHIPMENTORDER.shipmentOrder.shipments = values.shipments;
      SHIPMENTORDER.shipmentOrder.shipments.ship_from.address_line1= values.shipments.ship_from.apartment + ' ' + SHIPMENTORDER.shipmentOrder.shipments.ship_from.address_line1
      SHIPMENTORDER.shipmentOrder.shipments.ship_to.address_line1= values.shipments.ship_to.apartment + ' ' + SHIPMENTORDER.shipmentOrder.shipments.ship_to.address_line1
      SHIPMENTORDER.shipmentOrder.shipments.return_to.address_line1= values.shipments.return_to.apartment + ' ' + SHIPMENTORDER.shipmentOrder.shipments.return_to.address_line1
      console.log("SHIPMENT First", SHIPMENTORDER)
      dispatch(updateOrder(SHIPMENTORDER))
      navigate('/portal/shipment/shipment-info');
    }
  });

  return (
    <Container>
      <Typography variant='h5' align="left" display={'inline'}>Create Shipment</Typography>
      <Typography variant='body1' align="right" display={'inline'}>  (Step 1 of 5)</Typography>
      <Box>
        <form onSubmit={formik.handleSubmit}>

          <Card><CardContent>
            <Typography variant="h5" component="div" style={{ paddingTop: 10, paddingBottom: 10 }}>
              Ship From
            </Typography>

            <Grid container spacing={4}>

              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_from.name"
                  fullWidth
                  id="shipments.ship_from.name"
                  size="small"
                  autoFocus
                  label="Name"
                  value={formik.values.shipments.ship_from.name}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.ship_from?.name && Boolean(formik.errors.shipments?.ship_from?.name)}
                  helperText={formik.touched.shipments?.ship_from?.name && formik.errors.shipments?.ship_from?.name}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_from.company_name"
                  fullWidth
                  id="shipments.ship_from.company_name"
                  size="small"
                  autoFocus
                  label="Company Name"
                  value={formik.values.shipments.ship_from.company_name}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.ship_from?.company_name && Boolean(formik.errors.shipments?.ship_from?.company_name)}
                  helperText={formik.touched.shipments?.ship_from?.company_name && formik.errors.shipments?.ship_from?.company_name}

                />
              </Grid>

              <Grid item xs={12} md={4} >
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_from.phone"
                  fullWidth
                  id="shipments.ship_from.phone"
                  size="small"
                  autoFocus
                  label="Phone"
                  value={formik.values.shipments.ship_from.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.ship_from?.phone && Boolean(formik.errors.shipments?.ship_from?.phone)}
                  helperText={formik.touched.shipments?.ship_from?.phone && formik.errors.shipments?.ship_from?.phone}

                />
              </Grid>
              <Grid item xs={12} md={8} >
              <GooglePlacesAutocomplete
              apiKey="AIzaSyARY9vCtQv9W6FJjC9sb3xbtJeLC3hrtsE"
               selectProps={{
                onChange: (place: any) => {
                    console.log("place >>>>", place)
                    formik.setFieldValue('shipments.ship_from.location',place.value.description )
                    geocodeByPlaceId(place.value.place_id)            
                    .then(results => {
                      console.log("adressshipfrom",splitAddressComponents(results[0].address_components), results)
                      const { postal_code,
                        country,
                        administrative_area_level_1,
                        locality,
                        route,
                        street_number

                      } = splitAddressComponents(results[0].address_components);
                      formik.setFieldValue('shipments.ship_from.address_line1',street_number + ' '+ route)
                      formik.setFieldValue('shipments.ship_from.city_locality',locality )
                      formik.setFieldValue('shipments.ship_from.state_province',administrative_area_level_1 )
                      formik.setFieldValue('shipments.ship_from.country_code',country )
                      formik.setFieldValue('shipments.ship_from.postal_code',postal_code )
                      console.log("values,", formik.values)
                    })
                    .catch(error => console.error(error));
                },
                value: {
                  label: formik.values.shipments.ship_from.location, // Use label property
                  value: formik.values.shipments.ship_from.location, // Use value property
                }
              }}
            // Use onSelect to get the selected place
            />
     {formik.touched.shipments?.ship_from?.location && formik.errors?.shipments?.ship_from?.location ? (
  <div style={{
    color: '#d32f2f',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
    marginTop: '4px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px',
  }}>{formik.errors?.shipments?.ship_from?.location}</div>
) : null}

</Grid>
<Grid item xs={12} md={4}>
<div>
<FormControlLabel
                  control={<Checkbox 
                    checked={shipFromIndicator}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setShipFromIndicator(event.target.checked);
                      console.log("check>>>>>",event.target.checked)
                      if (event.target.checked) {
                        formik.values.shipments.ship_from.address_residential_indicator = "yes"
                      
                      } else {
                        formik.values.shipments.ship_from.address_residential_indicator = "no"

                      }
                    }}
                    onBlur={formik.handleBlur}
                    id="shipFromoIndicator"
                    name="shipFromIndicator"
                    color="primary" />}
                  label='It is a residential address'
                />
</div>
  </Grid>
<Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_from.apartment"
                  fullWidth
                  id="shipments.ship_from.apartment"
                  size="small"
                  autoFocus
                  label="Apartment, unit, suite, or floor #"
                  value={formik.values.shipments.ship_from.apartment}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.ship_from?.apartment && Boolean(formik.errors.shipments?.ship_from?.apartment)}
                  helperText={formik.touched.shipments?.ship_from?.apartment && formik.errors.shipments?.ship_from?.apartment}

                />
              </Grid>
 <Grid item xs={12} md={4} >
 <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_from.address_line1"
                  fullWidth
                  id="shipments.ship_from.address_line1"
                  size="small"
                  autoFocus
                  label="Address Line 1"
                  value={formik.values.shipments.ship_from.address_line1}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.ship_from?.address_line1 && Boolean(formik.errors.shipments?.ship_from?.address_line1)}
                  helperText={formik.touched.shipments?.ship_from?.address_line1 && formik.errors.shipments?.ship_from?.address_line1}

                />
              </Grid>
              <Grid item xs={12} md={4} >
     <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_from.city_locality"
                  fullWidth
                  id="shipments.ship_from.city_locality"
                  size="small"
                  autoFocus
                  label="City /Locality"
                  style={{zIndex:0}}
                  value={formik.values.shipments.ship_from.city_locality}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.ship_from?.city_locality && Boolean(formik.errors.shipments?.ship_from?.city_locality)}
                  helperText={formik.touched.shipments?.ship_from?.city_locality && formik.errors.shipments?.ship_from?.city_locality}

                /> 
              </Grid>
              <Grid item xs={12} md={4} >
   <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_from.state_province"
                  fullWidth
                  id="shipments.ship_from.state_province"
                  size="small"
                  autoFocus
                  label="State / Province"
                  value={formik.values.shipments.ship_from.state_province}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.ship_from?.state_province && Boolean(formik.errors.shipments?.ship_from?.state_province)}
                  helperText={formik.touched.shipments?.ship_from?.state_province && formik.errors.shipments?.ship_from?.state_province}

                /> 
              </Grid>
<Grid item xs={12} md={4} >                            <TextField
                autoComplete="shipToZip"
                name="shipments.ship_from.postal_code"
                fullWidth
                id="shipments.ship_from.postal_code"
                size="small"
                autoFocus
                label="Postal Code"
                value={formik.values.shipments.ship_from.postal_code}
                onChange={formik.handleChange}
                style={{zIndex:0}}
                error={formik.touched.shipments?.ship_from?.postal_code && Boolean(formik.errors.shipments?.ship_from?.postal_code)}
                helperText={formik.touched.shipments?.ship_from?.postal_code && formik.errors.shipments?.ship_from?.postal_code}

              />                           </Grid> 
              <Grid item xs={12} md={4} >
              <TextField
                autoComplete="shipToZip"
                name="shipments.ship_from.country_code"
                fullWidth
                id="shipments.ship_from.country_code"
                size="small"
                autoFocus
                label="Country"
                value={formik.values.shipments.ship_from.country_code}
                onChange={formik.handleChange}
                style={{zIndex:0}}
                error={formik.touched.shipments?.ship_from?.country_code && Boolean(formik.errors.shipments?.ship_from?.country_code)}
                helperText={formik.touched.shipments?.ship_from?.country_code && formik.errors.shipments?.ship_from?.country_code}

              />    

                {/* <FormControl fullWidth>
                  <Autocomplete
                    id="shipments.ship_from.country_code"
                    sx={{ width: 300 }}
                    options={countries}
                    autoHighlight
                    size="small"
                    readOnly
                    defaultValue={{
                      code: 'US',
                      label: 'United States',
                      phone: '1',
                      suggested: true,
                    }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </FormControl> */}


              </Grid>
              <Grid item xs={12} md={4}>
                {/* <Switch
    checked={Boolean(formik.values.shipments.ship_from.address_residential_indicator)}
    onChange={formik.handleChange}
    inputProps={{ 'aria-label': 'controlled' }}
    id="shipments.ship_from.address_residential_indicator"
    name="shipments.ship_from.address_residential_indicator"
    />  */}

              </Grid>
            </Grid>
            <Divider style={{ paddingTop: 10, paddingBottom: 10 }} />
            <Typography variant="h5" component="div" style={{ paddingTop: 10, paddingBottom: 10 }}>
              Ship To
            </Typography>


            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_to.name"
                  fullWidth
                  id="shipments.ship_to.name"
                  size="small"
                  autoFocus
                  label="Name"
                  value={formik.values.shipments.ship_to.name}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.ship_to?.name && Boolean(formik.errors.shipments?.ship_to?.name)}
                  helperText={formik.touched.shipments?.ship_to?.name && formik.errors.shipments?.ship_to?.name}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_to.company_name"
                  fullWidth
                  id="shipments.ship_to.company_name"
                  size="small"
                  autoFocus
                  label="Company Name"
                  value={formik.values.shipments.ship_to.company_name}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.ship_to?.company_name && Boolean(formik.errors.shipments?.ship_to?.company_name)}
                  helperText={formik.touched.shipments?.ship_to?.company_name && formik.errors.shipments?.ship_to?.company_name}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_to.phone"
                  fullWidth
                  id="shipments.ship_to.phone"
                  size="small"
                  autoFocus
                  label="Phone"
                  value={formik.values.shipments.ship_to.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.ship_to?.phone && Boolean(formik.errors.shipments?.ship_to?.phone)}
                  helperText={formik.touched.shipments?.ship_to?.phone && formik.errors.shipments?.ship_to?.phone}

                />
              </Grid>

              <Grid item xs={12} md={8} >
              <GooglePlacesAutocomplete
              apiKey="AIzaSyARY9vCtQv9W6FJjC9sb3xbtJeLC3hrtsE"
               selectProps={{
                onChange: (place: any) => {
                    console.log("place >>>>", place)
                    formik.setFieldValue('shipments.ship_to.location',place.value.description )
                    geocodeByPlaceId(place.value.place_id)            
                    .then(results => {
                      console.log("adressshipto",splitAddressComponents(results[0].address_components), results)
                      const { postal_code,
                        country,
                        administrative_area_level_1,
                        locality,
                        route,
                        street_number

                      } = splitAddressComponents(results[0].address_components);
                      formik.setFieldValue('shipments.ship_to.address_line1',street_number + ' '+ route)
                      formik.setFieldValue('shipments.ship_to.city_locality',locality )
                      formik.setFieldValue('shipments.ship_to.state_province',administrative_area_level_1 )
                      formik.setFieldValue('shipments.ship_to.country_code',country )
                      formik.setFieldValue('shipments.ship_to.postal_code',postal_code )
                      console.log("values,", formik.values)
                    })
                    .catch(error => console.error(error));
                },
                value: {
                  label: formik.values.shipments.ship_to.location, // Use label property
                  value: formik.values.shipments.ship_to.location, // Use value property
                }
              }}
            // Use onSelect to get the selected place
            />
     {formik.touched.shipments?.ship_to?.location && formik.errors?.shipments?.ship_to?.location ? (
  <div style={{
    color: '#d32f2f',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
    marginTop: '4px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px',
  }}>{formik.errors?.shipments?.ship_to?.location}</div>
) : null}

              </Grid>
              <Grid item xs={12} md={4}>
              <div>
<FormControlLabel
                  control={<Checkbox 
                    checked={shipToIndicator}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setShipToIndicator(event.target.checked);
                      console.log("check>>>>>",event.target.checked)
                      if (event.target.checked) {
                        formik.values.shipments.ship_to.address_residential_indicator = "yes"
                      
                      } else {
                        formik.values.shipments.ship_to.address_residential_indicator = "no"

                      }
                    }}
                    onBlur={formik.handleBlur}
                    id="shipToIndicator"
                    name="shipToIndicator"
                    color="primary" />}
                  label='It is a residential address'
                />
</div>
                </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_to.apartment"
                  fullWidth
                  id="shipments.ship_to.apartment"
                  size="small"
                  autoFocus
                  style={{zIndex:0}}
                  label="Apartment, unit, suite, or floor #"
                  value={formik.values.shipments.ship_to.apartment}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.ship_to?.apartment && Boolean(formik.errors.shipments?.ship_to?.apartment)}
                  helperText={formik.touched.shipments?.ship_to?.apartment && formik.errors.shipments?.ship_to?.apartment}

                />
              </Grid>
 <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_to.address_line1"
                  fullWidth
                  id="shipments.ship_to.address_line1"
                  size="small"
                  autoFocus
                  label="Address Line 1"
                  value={formik.values.shipments.ship_to.address_line1}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.ship_to?.address_line1 && Boolean(formik.errors.shipments?.ship_to?.address_line1)}
                  helperText={formik.touched.shipments?.ship_to?.address_line1 && formik.errors.shipments?.ship_to?.address_line1}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_to.city_locality"
                  fullWidth
                  id="shipments.ship_to.city_locality"
                  size="small"
                  autoFocus
                  label="City /Locality"
                  value={formik.values.shipments.ship_to.city_locality}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.ship_to?.city_locality && Boolean(formik.errors.shipments?.ship_to?.city_locality)}
                  helperText={formik.touched.shipments?.ship_to?.city_locality && formik.errors.shipments?.ship_to?.city_locality}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_to.state_province"
                  fullWidth
                  id="shipments.ship_to.state_province"
                  size="small"
                  autoFocus
                  label="State / Province"
                  value={formik.values.shipments.ship_to.state_province}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.ship_to?.state_province && Boolean(formik.errors.shipments?.ship_to?.state_province)}
                  helperText={formik.touched.shipments?.ship_to?.state_province && formik.errors.shipments?.ship_to?.state_province}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.ship_to.postal_code"
                  fullWidth
                  id="shipments.ship_to.postal_code"
                  size="small"
                  autoFocus
                  label="Postal Code"
                  value={formik.values.shipments.ship_to.postal_code}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.ship_to?.postal_code && Boolean(formik.errors.shipments?.ship_to?.postal_code)}
                  helperText={formik.touched.shipments?.ship_to?.postal_code && formik.errors.shipments?.ship_to?.postal_code}

                />
              </Grid>
              <Grid item xs={12} md={4} >
              <TextField
                autoComplete="shipToZip"
                name="shipments.ship_to.country_code"
                fullWidth
                id="shipments.ship_to.country_code"
                size="small"
                autoFocus
                label="Country"
                value={formik.values.shipments.ship_to.country_code}
                onChange={formik.handleChange}
                style={{zIndex:0}}
                error={formik.touched.shipments?.ship_to?.country_code && Boolean(formik.errors.shipments?.ship_to?.country_code)}
                helperText={formik.touched.shipments?.ship_to?.country_code && formik.errors.shipments?.ship_to?.country_code}

              />    
              </Grid>
              {/* <Grid item xs={12} md={4}>

                <FormControl fullWidth>
                  <Autocomplete
                    id="shipments.ship_to.country_code"
                    sx={{ width: 300 }}
                    options={countries}
                    autoHighlight
                    readOnly
                    size="small"
                    defaultValue={{
                      code: 'US',
                      label: 'United States',
                      phone: '1',
                      suggested: true,
                    }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </FormControl>

              </Grid>  */}
              <Grid item xs={12} md={4}>
                {/* <FormControl>
              <Switch
    checked={Boolean(formik.values.shipments.ship_to.address_residential_indicator)}
    inputProps={{ 'aria-label': 'controlled' }}
    id="shipments.ship_to.address_residential_indicator"
    name="shipments.ship_to.address_residential_indicator"
    onChange={formik.handleChange}

    />
    </FormControl> */}
              </Grid>
            </Grid>

            <Divider style={{ paddingTop: 10, paddingBottom: 10 }} />



            <Typography variant="h5" component="div" style={{ paddingTop: 10, paddingBottom: 10 }}>
              Return To
            </Typography>
            <FormControlLabel
                  control={<Checkbox 
                    checked={sameAddress}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSameAddress(event.target.checked);
                      console.log("check>>>>>",event.target.checked)
                      if (event.target.checked) {
                        formik.values.shipments.return_to = formik.values.shipments.ship_from
                        // formik.values.shipments.return_to.company_name =formik.values.shipments.ship_from.company_name
                        // formik.values.shipments.return_to.name = formik.values.shipments.ship_from.name
                        // formik.values.shipments.return_to.phone = formik.values.shipments.ship_from.phone
                        // formik.values.shipments.return_to.address_line1 = formik.values.shipments.ship_from.address_line1
                        // formik.values.shipments.return_to.city_locality = formik.values.shipments.ship_from.city_locality
                        // formik.values.shipments.return_to.state_province =formik.values.shipments.ship_from.state_province
                        // formik.values.shipments.return_to.postal_code =formik.values.shipments.ship_from.postal_code
                      } else {
                        formik.values.shipments.return_to.company_name =''
                        formik.values.shipments.return_to.name =''
                        formik.values.shipments.return_to.phone =''
                        formik.values.shipments.return_to.address_line1 =''
                        formik.values.shipments.return_to.city_locality =''
                        formik.values.shipments.return_to.state_province =''
                        formik.values.shipments.return_to.postal_code =''

                      }
                    }}
                    onBlur={formik.handleBlur}
                    id="acceptrefundpolicy"
                    name="acceptrefundpolicy"
                    color="primary" />}
                  label='Return address is same as ship from address'
                />
                

            <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.return_to.company_name"
                  fullWidth
                  id="shipments.return_to.company_name"
                  size="small"
                  autoFocus
                  label="Company Name"
                  value={formik.values.shipments.return_to.company_name}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.return_to?.company_name && Boolean(formik.errors.shipments?.return_to?.company_name)}
                  helperText={formik.touched.shipments?.return_to?.company_name && formik.errors.shipments?.return_to?.company_name}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.return_to.name"
                  fullWidth
                  id="shipments.return_to.name"
                  size="small"
                  autoFocus
                  label="Name"
                  value={formik.values.shipments.return_to.name}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.return_to?.name && Boolean(formik.errors.shipments?.return_to?.name)}
                  helperText={formik.touched.shipments?.return_to?.name && formik.errors.shipments?.return_to?.name}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.return_to.phone"
                  fullWidth
                  id="shipments.return_to.phone"
                  size="small"
                  autoFocus
                  label="Phone"
                  value={formik.values.shipments.return_to.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.shipments?.return_to?.phone && Boolean(formik.errors.shipments?.return_to?.phone)}
                  helperText={formik.touched.shipments?.return_to?.phone && formik.errors.shipments?.return_to?.phone}

                />
              </Grid>
              <Grid item xs={12} md={8} >
              <GooglePlacesAutocomplete
              apiKey="AIzaSyARY9vCtQv9W6FJjC9sb3xbtJeLC3hrtsE"
               selectProps={{
                onChange: (place: any) => {
                    console.log("place >>>>", place)
                    formik.setFieldValue('shipments.return_to.location',place.value.description )
                    geocodeByPlaceId(place.value.place_id)            
                    .then(results => {
                      console.log("adress",splitAddressComponents(results[0].address_components), results)
                      const { postal_code,
                        country,
                        administrative_area_level_1,
                        locality,
                        route,
                        street_number

                      } = splitAddressComponents(results[0].address_components);
                      formik.setFieldValue('shipments.return_to.address_line1',street_number + ' '+ route)
                      formik.setFieldValue('shipments.return_to.city_locality',locality )
                      formik.setFieldValue('shipments.return_to.state_province',administrative_area_level_1 )
                      formik.setFieldValue('shipments.return_to.country_code',country )
                      formik.setFieldValue('shipments.return_to.postal_code',postal_code )
                      formik.setFieldValue('shipments.return_to.address_residential_indicator',returnToIndicator )
                      console.log("values,", formik.values)
                    })
                    .catch(error => console.error(error));
                },
                value: {
                  label: formik.values.shipments.return_to.location, // Use label property
                  value: formik.values.shipments.return_to.location, // Use value property
                }
              }}
            // Use onSelect to get the selected place
            />
     {formik.touched.shipments?.return_to?.location && formik.errors?.shipments?.return_to?.location ? (
  <div style={{
    color: '#d32f2f',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
    marginTop: '4px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px',
  }}>{formik.errors?.shipments?.return_to?.location}</div>
) : null}

              </Grid>

              <Grid item xs={12} md={4}>
              <div>
<FormControlLabel
                  control={<Checkbox 
                    checked={returnToIndicator}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setReturnToIndicator(event.target.checked);
                      console.log("check>>>>>",event.target.checked)
                      if (event.target.checked) {
                        formik.values.shipments.return_to.address_residential_indicator = "yes"
                      
                      } else {
                        formik.values.shipments.return_to.address_residential_indicator = "no"

                      }
                    }}
                    onBlur={formik.handleBlur}
                    id="returnToIndicator"
                    name="returnToIndicator"
                    color="primary" />}
                  label='It is a residential address'
                />
</div>
                </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.return_to.apartment"
                  fullWidth
                  id="shipments.return_to.apartment"
                  size="small"
                  autoFocus
                  label="Apartment, unit, suite, or floor #"
                  value={formik.values.shipments.return_to.apartment}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.return_to?.apartment && Boolean(formik.errors.shipments?.return_to?.apartment)}
                  helperText={formik.touched.shipments?.return_to?.apartment && formik.errors.shipments?.return_to?.apartment}

                />
              </Grid>

  <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.return_to.address_line1"
                  fullWidth
                  id="shipments.return_to.address_line1"
                  size="small"
                  autoFocus
                  label="Address Line 1"
                  value={formik.values.shipments.return_to.address_line1}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.return_to?.address_line1 && Boolean(formik.errors.shipments?.return_to?.address_line1)}
                  helperText={formik.touched.shipments?.return_to?.address_line1 && formik.errors.shipments?.return_to?.address_line1}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.return_to.city_locality"
                  fullWidth
                  id="shipments.return_to.city_locality"
                  size="small"
                  autoFocus
                  label="City /Locality"
                  value={formik.values.shipments.return_to.city_locality}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.return_to?.city_locality && Boolean(formik.errors.shipments?.return_to?.city_locality)}
                  helperText={formik.touched.shipments?.return_to?.city_locality && formik.errors.shipments?.return_to?.city_locality}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.return_to.state_province"
                  fullWidth
                  id="shipments.return_to.state_province"
                  size="small"
                  autoFocus
                  label="State / Province"
                  value={formik.values.shipments.return_to.state_province}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.return_to?.state_province && Boolean(formik.errors.shipments?.return_to?.state_province)}
                  helperText={formik.touched.shipments?.return_to?.state_province && formik.errors.shipments?.return_to?.state_province}

                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="shipToZip"
                  name="shipments.return_to.postal_code"
                  fullWidth
                  id="shipments.return_to.postal_code"
                  size="small"
                  autoFocus
                  label="Postal Code"
                  value={formik.values.shipments.return_to.postal_code}
                  onChange={formik.handleChange}
                  style={{zIndex:0}}
                  error={formik.touched.shipments?.return_to?.postal_code && Boolean(formik.errors.shipments?.return_to?.postal_code)}
                  helperText={formik.touched.shipments?.return_to?.postal_code && formik.errors.shipments?.return_to?.postal_code}

                />
              </Grid>
              <Grid item xs={12} md={4} >
              <TextField
                autoComplete="shipToZip"
                name="shipments.return_to.country_code"
                fullWidth
                id="shipments.return_to.country_code"
                size="small"
                autoFocus
                label="Country"
                value={formik.values.shipments.return_to.country_code}
                onChange={formik.handleChange}
                style={{zIndex:0}}
                error={formik.touched.shipments?.return_to?.country_code && Boolean(formik.errors.shipments?.return_to?.country_code)}
                helperText={formik.touched.shipments?.return_to?.country_code && formik.errors.shipments?.return_to?.country_code}

              />    
              </Grid>
                        {/*   <Grid item xs={12} md={4}>

                <FormControl fullWidth>
                  <Autocomplete
                    id="shipments.return_to.country_code"
                    sx={{ width: 300 }}
                    options={countries}
                    autoHighlight
                    size="small"
                    readOnly
                    defaultValue={{
                      code: 'US',
                      label: 'United States',
                      phone: '1',
                      suggested: true,
                    }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </FormControl>


              </Grid> */}
                <Grid item xs={12} md={4}>

</Grid>
            </Grid>
              
                

            <Alert severity={(parseFloat(CUSTOMERPROFILE?.value?.wallet || '0') <= Number(DEFAULTS.MINBALANCE)) ? 'error' : 'warning'} style={{ marginTop: 20, marginBottom: 20 }} >Your wallet balance is  ${Number(CUSTOMERPROFILE?.value?.wallet).toFixed(2)} and may not be sufficient to complete the order. Please recharge</Alert>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{ paddingBottom: 5 }}
              type="submit"
            >
              Next
            </Button>



          </CardContent></Card>
          <Divider />


        </form>
      </Box>
    </Container>
  )
}

export default Shipment;