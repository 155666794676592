import React, {useState, useEffect} from 'react';
import {Container, Card,CardContent,FormControl,InputLabel,Alert,MenuItem, Select, RadioGroup, FormControlLabel, Radio, Button, Typography, Box} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';

import CircularProgress from '@mui/material/CircularProgress';

import { getDateTime, getDate } from '../../util/DisplayUtils';
import { useSelector } from 'react-redux'

import ImageIcon from '@mui/icons-material/Image';
//import trackData from '../../data/tracking.json'
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
  import { DEFAULTS } from '../../Constants/Constants'
  import { firebaseApp, analytics } from "../../lib/firebase";
import { logEvent } from "firebase/analytics";
type shipmentOrderType = {
  [key: string]: any;
}

const validationSchema = yup.object({
  carrierCode: yup
    .string()
    .notOneOf(['','none'], 'Please select a value') 
    .required('Enter a valid carrier'),
    trackingNumber: yup
    .string()
    .matches(/^[a-zA-Z0-9]*$/, 'Only alphabets and numbers are allowed')
    .min(9, 'Enter a valid tracking number')
    .max(50, 'Enter a valid tracking number')
    .required('Enter a valid tracking number')
    });

interface EventData {
    description: string;
    occurred_at: Date; // Make sure occurred_at is of type Date
  }
  
  interface TrackData {
    events: EventData[];
  }
  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

function Tracking() {
    const { carrier_code,tracking_number } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [shipmentOrder, setShipmentOrder] = useState<{[key: string]: any}>({});
    const [options, setOptions] = useState<string[]>([])
    const [trackData, setTrackData] = useState<{[key: string]: any}>({});
    const [shipmentOrderFull, setShipmentOrderFull] = useState<{[key: string]: any}>({});
    const token = localStorage.getItem('user-token');
    let navigate = useNavigate();
    const getShipment = async (trackingParams: any) => {
   // await axios.post(DEFAULTS.API_URL+`tracking/${carrier_code}/${tracking_number}`,'',
   setLoading(true)
    await axios.post(DEFAULTS.API_URL+'tracking',trackingParams)
    .then(
      response => {
        console.log(response.data)
        if (response.data)
        {
          setTrackData(response.data)
setLoading(false)           
        }
      }
    )
      
  }


  async function getCarriers() {
    try {
      // Replace with your actual data fetching logic
      const response = await fetch(DEFAULTS.API_URL+'carriers');
      const data = await response.json();
      setLoading(false)
      return data; // Assuming data is an array of strings
    } catch (error) {
      console.error('Error fetching options:', error);
      return []; // Return an empty array or handle the error appropriately
    }
  }


  const formik = useFormik({
    initialValues: {
        trackingNumber: '',
        carrierCode: 'ups'
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     console.log("values", values)
     getShipment(values)
      
    },
  });

  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Container>
      <Typography variant='h5' align="left" display={'inline'}>Tracking </Typography>
     
     
      <Card style={{marginBottom:50}}>
          <CardContent>
            <Box component="form" onSubmit={formik.handleSubmit}>
              <p>Please select the carrier and enter your tracking number to track your shipment.</p>
     <Grid container spacing={4}>
                <Grid item xs={4}>
                <InputLabel id="dimension-label">Select a carrier</InputLabel>
               
          <Select
        value={formik.values.carrierCode}
        labelId="dimension-label"
        id="carrierCode"
        size="small"
        label="Select a carrier"
        name="carrierCode"
        fullWidth
        onChange={formik.handleChange}


      >
        {DEFAULTS.TRACKINGCARRIERS.map((option: any, index: any) => (
          <MenuItem key={index} value={option.carrier_code}>
            {option.friendly_name}
          </MenuItem>
        ))}
      </Select>

      </Grid>
      <Grid item xs={4}>
      <InputLabel>
Tracking Number
        </InputLabel>
             <TextField

                fullWidth
                id="trackingNumber"
                name="trackingNumber"
                type="text"
                autoComplete="passwordnew"
                autoFocus
                size="small"
                value={formik.values.trackingNumber}
                onChange={formik.handleChange}
                error={formik.touched.trackingNumber && Boolean(formik.errors.trackingNumber)}
                helperText={formik.touched.trackingNumber && formik.errors.trackingNumber}
              />
          </Grid>
          <Grid item xs={2}>
              <Button
                type="submit"
                variant="contained" style={{backgroundColor:'#eb4034'}}

                sx={{ mt: 3, mb: 2 }}

              >
                TRACK
              </Button>
              </Grid> </Grid>
              </Box>
</CardContent>
</Card>

        <Card>
          <CardContent>
            {loading && <CircularProgress/>}
          {!isEmptyObject(trackData) ? (
  (trackData.status_code === 'EX' || trackData.status_code === 'UN' || trackData.status_code === 'NY') ? (
    <>{trackData.carrier_status_description}</>
  ) : (
    <>
      <p>Tracking Number: {trackData.tracking_number}</p>
      <p>Carrier Code: {trackData.carrier_code}</p>
      <p>Status: {trackData.status_description}</p>
      <p>Description: {trackData.carrier_status_description}</p>

      {trackData.events &&
        trackData.events
          .sort((a: any, b: any) => Date.parse(a.occurred_at) - Date.parse(b.occurred_at))
          .map((eventData: any, index: any) => (
            <p>
              {getDateTime(eventData.occurred_at)} - {eventData.description}
            </p>
          ))}
    </>
  )
) : ''}          
          </CardContent>
        </Card>


    </Container>
  );
}

export default Tracking;
