import axios, { AxiosResponse } from 'axios';
import { DEFAULTS } from '../Constants/Constants'

const API_BASE_URL = DEFAULTS.API_URL; // Replace with your API base URL

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

const token = localStorage.getItem('user-token');
console.log('token -apits', token)

export interface ProfileData {
  // Define your profile data structure here
  userid: number;
  firstname: string;
  lastname:string;
  username:string;
  company:string;
  mobile:string;
  wallet: 0;
  _id:string;
  status: string;
  // Add other properties as needed
}

export const fetchProfileData = async (): Promise<AxiosResponse<ProfileData>> => {
  console.log("calling")
  try {
    const response = await axiosInstance.get<ProfileData>('/profile',{headers: {
      'Authorization': `Bearer ${token}`
    }}); // Replace with your API endpoint
    return response;
  } catch (error) {
    throw error;
  }
};
