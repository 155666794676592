import React, {useState, useEffect} from 'react';
import {Container, Card, CardContent, Alert, RadioGroup, FormControlLabel, Radio, Button, Typography, Box} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { getDateTime } from '../../../../util/DisplayUtils';
import { useSelector } from 'react-redux'
import { getDate } from '../../../../util/DisplayUtils';
import ImageIcon from '@mui/icons-material/Image';
import trackData from '../../../../data/tracking.json'
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { updateOrder,
  getOrder,
  selectShipmentOrder } from '../../../../features/shipment/shipmentSlice'
  import { DEFAULTS } from '../../../../Constants/Constants'
type shipmentOrderType = {
  [key: string]: any;
}

interface EventData {
    description: string;
    occurred_at: Date; // Make sure occurred_at is of type Date
  }
  
  interface TrackData {
    events: EventData[];
  }

function Tracking() {
    const { carrier_code,tracking_number } = useParams();
    const [shipmentOrder, setShipmentOrder] = useState<{[key: string]: any}>({});
    const [shipmentOrderFull, setShipmentOrderFull] = useState<{[key: string]: any}>({});
    const token = localStorage.getItem('user-token');
    let navigate = useNavigate();
    const getShipment = async () => {
    await axios.post(DEFAULTS.API_URL+`tracking/${carrier_code}/${tracking_number}`,'',
    {headers: {
      'Authorization': `Bearer ${token}`
    }})
    .then(
      response => {
        console.log(response)
        if (response.data.shipmentId)
        {
            setShipmentOrder(response.data.shipmentOrderRecord.shipmentOrder)
            setShipmentOrderFull(response.data)
            console.log("order",response.data.shipmentOrderRecord)
            
        }
      }
    )
      
  }
  useEffect(() => {

  getShipment()

  },[])


  return (
    <Container>
      <Typography variant='h5' align="left" display={'inline'}>Tracking  : {trackData.tracking_number}</Typography>
      <Box>
        <Card>
          <CardContent>
          <b>Tracking Information</b>
        {(trackData.status_code === 'EX' || trackData.status_code === 'UN') ? (
          <>
          {trackData.exception_description}
          </>
           ) : (
            <>
            <p>Tracking Number : {trackData.tracking_number}</p>
            <p>Carrier Code : {trackData.carrier_code.toUpperCase()}</p>
            <p>Status : {trackData.status_description}</p>
            <p>Description:{trackData.carrier_status_description}</p>

          

          {trackData.events.sort((a: any, b: any) => {
        return Date.parse(a.occurred_at) - Date.parse(b.occurred_at);
    }).map((eventData: any, index: any) => (
                     <p>{getDateTime(eventData.occurred_at)} - {eventData.description}</p>
                    ))}
                    </>
                    
           )
    } 
           
          </CardContent>
        </Card>

      </Box>
    </Container>
  );
}

export default Tracking;
