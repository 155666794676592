import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField, Button, InputLabel, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import ToggleButton from '@mui/material/ToggleButton';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { Formik, Form, Field, FieldArray, getIn, ErrorMessage, FormikConsumer } from 'formik';
import { DEFAULTS } from '../../../../Constants/Constants'
type MyState = Array<any> | Record<string, any>;
const convertFirestoreTimestampToDate = (timestamp: any): string => {
  const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6); // Convert Firestore Timestamp to JavaScript Date
  return date.toLocaleString(); // Convert Date to a readable date and time format
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const positive = {
  color:'green'
}
const errorStyle ={
  color:'red',
  fontSize:'9pt',
  marginTop: 2
}
function Super() {return(<span style={{ color: 'red', verticalAlign: 'super' }}>*</span>)}


function Quote() {

  const pre = (ship_type: any) => {
    switch (ship_type) {
      case 'air':
        return "A1";
      case 'ocean':
        return "S1";
      default:
        return "T1";
    }
  };
  const { id, quoteId } = useParams();
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState<{[key: string]: any}>({});
  const [zohoQuote, setZohoQuote] = useState<{[key: string]: any}>({});
  //const [zohoQuote, setZohoQuote] = useState([key: string])
  const token = localStorage.getItem('user-token');
  let navigate = useNavigate();
  const getQuote = async () => {
  await axios.get(DEFAULTS.API_URL+`v1/customer/quote/${id}`,
  {headers: {
    'Authorization': `Bearer ${token}`
  }}
  )
  .then(
    response => {
      console.log(response)
      if (response.data)
      {
        setQuote(response.data)
        //getZohoQuote(response.data.zohoQuoteId)
      }
    }
  )
    
}
const handleSubmit = ()=>{

}




useEffect(() => {



const getZohoQuote = async (quoteId: any) => {
  await axios.get(DEFAULTS.API_URL+`v1/customer/zoho-quote/${quoteId}`,
  {headers: {
    'Authorization': `Bearer ${token}`
  }}
  )
  .then(
    response => {
      console.log(response)
      if (response.data)
      {
        //console.log("quoteData",response.data.data[0])
        setZohoQuote(response.data)
        setLoading(false)
        
      }
    }
  )
    
}
getQuote()
getZohoQuote(quoteId)
console.log("quoteData----",zohoQuote)

},[quoteId])




const itemRecord= {}
    return (
      <div>
        
        <Container>
        <Typography variant="h5">Quote Detail : {pre(quote?.quoteDetails?.ship_type) + quote?.quoteId}</Typography>
            <Card variant="outlined">
                <CardContent>
                {(Object.keys(quote).length !== 0) && <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={3}>
                  <span className="Qhead">Quote Id</span><br />
                {pre(quote?.quoteDetails?.ship_type) + quote?.quoteId}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Quote Date</span><br />
                  {convertFirestoreTimestampToDate(quote?.quoteDate)}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Assigned To</span><br />
                  {quote?.quoteAssigned?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Ship Date</span><br />
{quote?.quoteDetails?.ship_date}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Mobile</span><br />
                {quote?.quoteMobile}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Name</span><br />
                  {quote?.quoteName?.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Email</span><br />
                  {quote?.quoteEmail?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Requester</span><br />
{quote?.quoteDetails?.requester_type?.toUpperCase()}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Shipment Type</span><br />
                {quote?.quoteDetails?.ship_type.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Load Type</span><br />
                  {quote?.quoteDetails?.load_type.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Inco Term</span><br />
                {quote?.quoteDetails?.inco_term?.toUpperCase()}

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Currency</span><br />
{quote?.quoteDetails?.currency?.toUpperCase()}
                </Grid>
                

                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
               
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From</span><br />
                {quote?.quoteDetails?.from_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From Address</span><br />
                {quote?.quoteDetails?.to_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To</span><br />
                {quote?.quoteDetails?.to_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To Address</span><br />
                {quote?.quoteDetails?.to_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
{(zohoQuote?.data?.[0].Quote_Stage ==="Draft") && <>
                <Grid item xs={12} sm={12} md={4}>
                <span className="Qhead">Additionals</span><br />
                  <ul>
                {quote?.quoteDetails?.additionals?.map((item: any, index: number) => (
                  <li>{item.label} - {item.required? 'YES':'NO'}</li>))}
</ul>
                </Grid>
                

                {(quote?.quoteDetails?.load_type === 'lcl' || quote?.quoteDetails?.load_type === 'ltl') && <Grid item xs={12} sm={12} md={8}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Quantity</td>
                      <td>Packaging</td>
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      </tr>
  {quote?.quoteDetails?.items?.map((item: any, index: number) => (
    <tr key={index}>
      <td>{item.quantity}</td>
                      <td>{item.packaging}</td>
                      <td>{item.weight.value}  {item.weight.unit}</td>
                      <td>{item.dimensions.height} X {item.dimensions.width} X {item.dimensions.length} {item.dimensions.unit}</td>
  </tr>
  ))}
</table></Grid>}

{(quote?.quoteDetails?.load_type === 'fcl' || quote?.quoteDetails?.load_type === 'ftl') && <Grid item xs={12} sm={12} md={8}>
<span className="Qhead">Cargo Details</span><br /><br />
<table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr><td>Quantity</td><td>{quote?.quoteDetails?.full_load_quantity}</td></tr>
                      
                    <tr><td>Container</td><td>{quote?.quoteDetails?.full_load_container}</td></tr>
                    <tr><td>Container Type</td><td>{quote?.quoteDetails?.full_load_container_type}</td></tr>

                    <tr><td>Weight</td><td>{quote?.quoteDetails?.full_load_weight} - {quote?.quoteDetails?.full_load_weight_unit}</td></tr>
                    <tr><td>Commodity</td><td>{quote?.quoteDetails?.commodity}</td></tr>
                      </table>
  
  

  
  
  
</Grid>}  

</>}



                </Grid>}

                {(!loading) && <Formik
          initialValues={zohoQuote?.data && zohoQuote?.data.length > 0 ? zohoQuote?.data?.[0] : {}}
          //validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={({ values, isSubmitting, errors, touched , handleChange, handleBlur, isValid, setFieldValue }) => (
            <Form>
              {(values.Quote_Stage=== "Negotiation") && <>
              <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      
                      <td>Packaging</td>
                      <td>Dimensions (H X W X L)</td>
                     
                      <td>Weight</td>
                      <td>Quantity</td>
                      <td>Price ($)</td>
                      <td>Total ($)</td>
                      </tr>

              {values?.Additionals?.map((additional: any, index: number) => (
                <tr key={index}>
                  <td>-</td>
                  

                  <td>{additional.Length} X {additional.Width} X {additional.Height} {additional.Units}</td>

                  <td>{additional.Weight} {additional.Unit}</td>
                  <td>{additional.Quantity}</td>
                  <td>{additional.Price}</td>
                  <td>{additional.Totals}</td>
                </tr>
              ))}
              <tr><td colSpan={6}>Additionals</td></tr>
              {values?.Quoted_Items?.map((item: any, index: number) => (
                <tr key={index}>
                  <td colSpan={3}>{item.Product_Name.name}</td>
                  <td>{item.Quantity}</td>
                  <td>{item.Net_Total}</td>
                  <td>{item.Total}</td>
                  </tr>
              ))}
      
      <tr><td colSpan={5}>Grand Total</td>
      <td>{values.Final_Total}</td>
      </tr>
</table>

              
 <FieldArray name="Additionals">
                      {({ insert, remove, push }) => (
                        <>

{values?.Additionals?.map((additional: any, index: number) => (
                <div key={index}>
                  {/* Render fields for each additional */}
                  {/* <Field name={`Additionals[${index}].Quantity`} />
                  <Field name={`Additionals[${index}].Unit`} />
                  <Field name={`Additionals[${index}].Height`} />
                  <Field name={`Additionals[${index}].Length`} />
                  <Field name={`Additionals[${index}].Width`} />
                  <Field name={`Additionals[${index}].Units`} /> */}

                  {/* Add other fields as needed */}
                </div>
              ))}
                        </>
                      )}
                    </FieldArray>  
<div>
  <h6>Terms & Conditions</h6>
  <p>
  {values?.Terms_and_Conditions}
  </p>
</div>
<Button type="submit">Accept Quote and Make Payment</Button> <Button>Rework Quote</Button>

                    </>}
                    </Form>
          )} />}
</CardContent>
</Card>
      </Container>

      </div>
    );
  }

  interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
  }
  
  
  export default Quote;