import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCK7g4qafGuGqd85Wn1OiAX2kovC9KjK-M",
    authDomain: "ikeocean-94603.firebaseapp.com",
    projectId: "ikeocean-94603",
    storageBucket: "ikeocean-94603.appspot.com",
    messagingSenderId: "627014886127",
    appId: "1:627014886127:web:e0ea528bd0fc184b0e311a",
    measurementId: "G-R1DNSVFPQ5"
  };

  export const firebaseApp = initializeApp(firebaseConfig);
  export const analytics = getAnalytics(firebaseApp);



