// DataLoading.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { fetchDataStart, fetchDataSuccess, fetchDataFailure } from './dataSlice';
import { BrowserRouter, Routes, Route,Outlet, useNavigate } from "react-router-dom";
import Loading from './Loading';
import Dashboard from '../Dashboard/Dashboard';
//import { RootState } from './store'; // Adjust the import path
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import {store, RootState} from  '../../../../store'
import { updateProfile,
  selectProfile, fetchProfile} from '../../../../features/profile/profileSlice'
  type customerProfileType = {
    [key: string]: any;
  }

const DataLoader: React.FC = () => {
  const [isLoading, setLoading] = React.useState(true);
  let navigate = useNavigate();
  //const isLoading = useSelector((state: RootState) => state.data.isLoading);
  //const isLoading = false;

  const CUSTOMERPROFILE : customerProfileType = useSelector((state: RootState) => state.profile);
  const dispatch = useAppDispatch();
  useEffect(() => {
   // dispatch(fetchProfile());
setTimeout(() => {
setLoading(false)
navigate('/portal/dashboard')
}, 5000); 
  }, [dispatch]);
 // let CUSTOMERPROFILE : customerProfileType = useAppSelector(fetchProfile)

  console.log("CUSTOMERPROFILE", CUSTOMERPROFILE?.value, CUSTOMERPROFILE?.value?.firstname)
//   useEffect(() => {
//     dispatch(fetchDataStart());

//     // Simulate data fetching (replace with your actual API call)
//     setTimeout(() => {
//       const fakeData = 'Your data here';
//       dispatch(fetchDataSuccess(fakeData));
//     }, 2000); // Replace with your API call

//     // Handle error (if needed)
//     // dispatch(fetchDataFailure());

//   }, [dispatch]);

  return (
    <div className="data-loading">
      {isLoading && <Loading />}
    </div>
  );
};

export default DataLoader;
