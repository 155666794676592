import React , {useState} from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import {TextField, Container,Card, CardContent,Divider} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DEFAULTS } from '../../../../Constants/Constants'


const theme = createTheme();

const validationSchema = yup.object({
  companyName: yup
  .string()
  .min(3, 'Enter a valid fist name')
  .max(20, 'Enter a valid fist name')
  .required('Enter first name')
  .matches(/^[a-z\s]+$/,'Enter a valid fist name'),
  displayName: yup
  .string()
  .min(3, 'Enter a valid last name')
  .max(20, 'Enter a valid last name')
  .required('Enter last name')
  .matches(/^[a-z\s]+$/,'Enter a valid last name'),
  webSite: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number'),
  phoneNumber: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number'),
  regNumber: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number'),
  email: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number'),
  fbUrl: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number'),
  instaUrl: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number'),
  twitterUrl: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number'),
  youTubeUrl: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number'),
});
const dataCompany =  {
  companyName: 'xxx',
  displayName: 'yyy',
  phoneNumber: '1234567',
  email:'test@test.com',
  regNumber:'12322323',
  webSite:'www.test.com',
  instaUrl:'https://instagram.com/test',
  twitterUrl:'https://twitter.com/test',
  fbUrl:'https://facebook.com/test',
  youTubeUrl:'https://youtube.com/test'
}

export default function Company() {
  const navigate = useNavigate();
  const[formSuccess, setFormSuccess] = useState(false);
  const formik = useFormik({
    initialValues: dataCompany,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     console.log("values", values)
       //localStorage.setItem('user-token', 'nakkkalasaxallasa');
    setTimeout(() => {
        navigate('/');
    }, 500);
     setFormSuccess(true);
    },
  });

  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //   });
  //   localStorage.setItem('user-token', 'nakkkalasaxallasa');
  //   setTimeout(() => {
  //       navigate('/');
  //   }, 500);
  // };

  return (
    <Container>

        <Typography variant="h5">Company</Typography>
            <Card variant="outlined">
                <CardContent>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="companyName"
                  fullWidth
                  id="companyName"
                  label="Company Name"
                  autoFocus
                  size="small"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={formik.touched.companyName && formik.errors.companyName}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="displayName"
                  label="Display Name"
                  name="displayName"
                  size="small"
                  autoComplete="family-name"
                  value={formik.values.displayName}
                  onChange={formik.handleChange}
                  error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                  helperText={formik.touched.displayName && formik.errors.displayName}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="regNumber"
                  label="Business Registration Number"
                  name="regNumber"
                  autoComplete="email"
                  size="small"
                  value={formik.values.regNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.regNumber && Boolean(formik.errors.regNumber)}
                  helperText={formik.touched.regNumber && formik.errors.regNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Divider />
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="phoneNumber"
                  label="Business Phone Number"
                  name="phoneNumber"
                  autoComplete="email"
                  size="small"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="webSite"
                  label="Mobile"
                  name="webSite"
                  autoComplete="email"
                  size="small"
                  value={formik.values.webSite}
                  onChange={formik.handleChange}
                  error={formik.touched.webSite && Boolean(formik.errors.webSite)}
                  helperText={formik.touched.webSite && formik.errors.webSite}
                />
              </Grid>
              <Grid item xs={12} sm={4} />
              <Divider />
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="fbUrl"
                  label="Facebook URL"
                  name="fbUrl"
                  autoComplete="email"
                  size="small"
                  value={formik.values.fbUrl}
                  onChange={formik.handleChange}
                  error={formik.touched.fbUrl && Boolean(formik.errors.fbUrl)}
                  helperText={formik.touched.fbUrl && formik.errors.fbUrl}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="instaUrl"
                  label="Instagram URL"
                  name="instaUrl"
                  autoComplete="email"
                  size="small"
                  value={formik.values.instaUrl}
                  onChange={formik.handleChange}
                  error={formik.touched.instaUrl && Boolean(formik.errors.instaUrl)}
                  helperText={formik.touched.instaUrl && formik.errors.instaUrl}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="twitterUrl"
                  label="Twitter URL"
                  name="twitterUrl"
                  autoComplete="email"
                  size="small"
                  value={formik.values.twitterUrl}
                  onChange={formik.handleChange}
                  error={formik.touched.twitterUrl && Boolean(formik.errors.twitterUrl)}
                  helperText={formik.touched.twitterUrl && formik.errors.twitterUrl}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="twitterUrl"
                  label="YouTube URL"
                  name="twitterUrl"
                  autoComplete="email"
                  size="small"
                  value={formik.values.youTubeUrl}
                  onChange={formik.handleChange}
                  error={formik.touched.youTubeUrl && Boolean(formik.errors.youTubeUrl)}
                  helperText={formik.touched.youTubeUrl && formik.errors.youTubeUrl}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"

              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save Company Details
            </Button>
            {/* <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid> */}
          </Box>

          </CardContent>
          </Card>
          


          </Container>
  );
}