import React , {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {Alert, Grid} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import LogoComponent from './LogoComponent'
import CopyRight from './CopyRight'
import * as yup from 'yup';
import axios from 'axios';
import { DEFAULTS } from '../../Constants/Constants'
import { firebaseApp, analytics } from "../../lib/firebase";
import { logEvent } from "firebase/analytics";

type FormError = {
  status: string,
  msg:string
  // Add other properties if needed, for example:
  // message: string;
};
const theme = createTheme();

const validationSchema = yup.object({
  email: yup
  .string().email()
  .min(9, 'Enter a valid email')
  .max(50, 'Enter a valid email')
  .required('Enter an email'),
  });

export default function ForgotPassword() {
  const navigate = useNavigate();
  const[showAlert, setAlert] = useState(false);
  const[status, setStatus] = useState('');
  const [formError, setFormError] = useState<FormError>({ status: '', msg:'' });
  const formik = useFormik({

    initialValues: {
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     console.log("values", values)




     const expensesListResp = async () => {
      try {
        await axios.post( DEFAULTS.API_URL + 'forgot', values)
        .then(
          response => {
            console.log(response)
            setFormError(response.data);
                     }
        )
      } catch (error) {
        console.error('Error fetching records:', error);
        // Handle errors or set a default error state.
        setFormError({ status: 'error', msg: 'Failed to fetch data.' });
      }
          
      }
      expensesListResp()
    },
  });
  const isSuccess = formError?.status === 'success';
  const isError = formError?.status === 'error';
  useEffect(() => {
    logEvent(analytics, 'Forgot Password', {
content_type: 'page',
content_id: 'forgot-password'
});

}, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: 'url(https://ikeocean.com/wp-content/uploads/2020/05/containers-1.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <LogoComponent /> */}
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            
            <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{backgroundColor:'#eb4034'}}

              >
                Recover Password
              </Button>
              { isSuccess && (
                <Alert severity='success'>{formError.msg}</Alert>
              )}
                            { isError && (
                <Alert severity='error'>{formError.msg}</Alert>
              )}
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Link href="/auth/login" variant="body2">
                    Sign In
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Link href="/auth/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              {/* <CopyRight sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}