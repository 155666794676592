import React, {useState, useEffect} from 'react';

import {TextField, Container,Card, CardContent,Divider, Typography} from '@mui/material';
import { BrowserRouter, Routes, Route,  Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import axios from 'axios';

import Autocomplete from '@mui/material/Autocomplete';

import { DataGrid } from '@mui/x-data-grid';

import { DEFAULTS } from '../../../../Constants/Constants'

function Shipments() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const token = localStorage.getItem('user-token');
  console.log('token', token)
  const clickShipment = () => {
    console.log("logout")
    navigate('/portal/shipments')
  };
  const getShipment = (shipmentId: any) => {
    console.log("logout",shipmentId)
    navigate(`/portal/shipment/${shipmentId}`)
  };
  const getRecords = async () => {
    setLoading(true)
    const response = await fetch(DEFAULTS.API_URL+'shipments',  {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    let records = await response.json();
    records = records.sort((a: any, b: any) => {
      let da: any = new Date(b.shipmentId)
      let   db: any = new Date(a.shipmentId)
      return da - db;
    });
    console.log("records",records)
    setRecords(records);
    setLoading(false)
    }
  useEffect(() => {
    getRecords();

  },[]);

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };

  

  const convertFirestoreTimestampToDate = (timestamp: any): string => {
    const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6); // Convert Firestore Timestamp to JavaScript Date
    return date.toLocaleString(); // Convert Date to a readable date and time format
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }

    return (
      <div>
         <Container>

<Typography variant='h4'>Shipments</Typography>
    <Card variant="outlined">
        <CardContent>
        {loading && (<LinearProgress color="inherit" />)}
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small'>
        <TableHead>
          <TableRow>
          <TableCell>Order Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Shipment Date</TableCell>
            <TableCell>Delivery Date</TableCell>
            <TableCell>Ship To</TableCell>
            <TableCell>Method</TableCell>
            <TableCell >Amount(USD)</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           {records?.map((row: any, idx: any) =>  (
            <TableRow key={row['_id']}>
              <TableCell>
              <Link color="primary" href="" onClick={()=>getShipment(row['shipmentId'])}>{row['shipmentId']}</Link></TableCell>
              <TableCell>{convertFirestoreTimestampToDate(row['shipmentOrderDate'])}</TableCell>
              <TableCell>{getDate(row.shipmentOrderRecord.shipmentOrder.rate.shipDate)}</TableCell>
              <TableCell>{getDate(row.shipmentOrderRecord.shipmentOrder.rate.estimatedDeliveryDate)}</TableCell>
              <TableCell>{getName(row)}</TableCell>
              
              <TableCell>{row.shipmentOrderRecord.shipmentOrder.rate.serviceType}</TableCell>
              <TableCell align="right">{Number(row['shipmentGrandTotal']).toFixed(2)}</TableCell>
              <TableCell>{row['shipmentStatus']}</TableCell>
            </TableRow>
          ))} 
        </TableBody>
      </Table>
      </TableContainer>
          </CardContent>
          </Card>
          </Container>
          </div>
    );
  }

  

  export default Shipments;