import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import InboxIcon from '@mui/icons-material/Inbox';
import WalletIcon from '@mui/icons-material/Wallet';
import DraftsIcon from '@mui/icons-material/Drafts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import BusinessIcon from '@mui/icons-material/Business';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import CopyRight from '../../../../auth/Login/CopyRight'

import Paper from '@mui/material/Paper';

function Navigation() {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

    return (
      <div style={{height: '95vh'}}>
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon style={{color:'#ED1C24'}}/>
              </ListItemIcon>
              <Link href="/portal/dashboard"><ListItemText primary="Dashboard" /></Link> 
            </ListItemButton>
          </ListItem>
         

        <ListItemButton onClick={handleClick}>
        <ListItemIcon>
        <RequestQuoteIcon style={{color:'#ED1C24'}}/>
        </ListItemIcon>
        <ListItemText primary="Air/Sea/Truck Quote" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} >
          <Link href="/portal/quote"><ListItemText primary="Create Quote" /></Link> 
                      </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} >
          <Link href="/portal/quotes"><ListItemText primary="Manage Quotes" /></Link>
          </ListItemButton>
        </List>
      </Collapse>
          

      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
        <LocalShippingIcon style={{color:'#ED1C24'}}/>
        </ListItemIcon>
        <ListItemText primary="Local Courier" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} >
          <Link href="/portal/shipment/basic"><ListItemText primary="Create Shipment" /></Link> 
                      </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} >
          <Link href="/portal/shipments"><ListItemText primary="Manage Shipments" /></Link>
          </ListItemButton>
        </List>
      </Collapse>


          
             <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <WalletIcon style={{color:'#ED1C24'}}/>
              </ListItemIcon>
              <Link href="/portal/wallet"><ListItemText primary="Wallet" /></Link> 
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <Link href="/portal/reports"><ListItemText primary="Reports" /></Link> 
            </ListItemButton>
          </ListItem> */}
          <Divider />
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LiveHelpIcon style={{color:'#ED1C24'}}/>
              </ListItemIcon>
              <Link href="/portal/help"><ListItemText primary="Help" /></Link> 
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon style={{color:'#ED1C24'}}/>
              </ListItemIcon>
              <Link href="https://ikeocean.com" ><ListItemText primary="ikeocean.com" /></Link> 
            </ListItemButton>
          </ListItem>

      
        </List> 

        {/* <CopyRight sx={{ mt: 5 }} /> */}
        </div>

    );
  }

  

  export default Navigation;