import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, TextField, Button, InputLabel, Select, MenuItem, AlertTitle, FormControl, Card, CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import ToggleButton from '@mui/material/ToggleButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getPackageName } from '../../../../util/DisplayUtils';
import { DEFAULTS } from '../../../../Constants/Constants'
import moment from 'moment';
import axios from 'axios';
import Loading from '../../pages/Loading/Loading'

interface MyType {
  service_code: string;
  service_type: string;
  carrier_delivery_days: number;
  error_messages: string[];
  shipping_amount: {
    amount: number;
    // Other properties related to shipping_amount
  };
  // Other properties
}
interface RateType {
  service_code: string;
  mark_up: string;
  // Other properties related to rates
}
//const RatesPanel = ({ rates }: any) => {
const RatesPanel = React.memo(({ rates }: any) => {
  const [shipRates, setShipRates] = useState<{ [key: string]: any }>({});
  const [shipErrors, setShipError] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isError, setError] = React.useState(false);

  useEffect(() => {
    // Update the document title using the browser API
getRates(rates) 

  }, [rates]);
  const getRates = async (rates: any) => {
    setLoading(true);
    setError(false)
    const removeShipType = (obj: any) => {
      
        delete obj.item.weight.ship_type;
     
        delete obj.item.dimensions.ship_type;
     
      return obj;
    };
const newRates =removeShipType(rates)
    try {
      const response = await axios.post(DEFAULTS.API_URL + 'estimate',newRates );

      if (response.status === 500 && (response.data.errors.length >0)) {
        //console.error('Internal server error occurred');
        setError(true)
        setShipError(response.data.errors)
      } else {
        const myArray: MyType[] = response.data;
        const rates: RateType[] = DEFAULTS.MARKUP;

        const filteredAndModifiedArray = myArray
          .filter(item => item.error_messages.length === 0)
          .map(item => {
            const rate = rates.find(rateItem => rateItem.service_code === item.service_code);

            if (rate) {
              const modifiedAmount = item.shipping_amount.amount * Number(rate.mark_up);

              return {
                ...item,
                shipping_amount: {
                  amount: modifiedAmount,
                  // Other properties related to shipping_amount
                },
              };
            }
            return item; // If no rate is found, leave the item unchanged
          });

        // Step 2: Sort the filtered and modified array
        const sortedArray = filteredAndModifiedArray.sort((a, b) => {
          // First, sort by service_type in ascending order
          const serviceTypeComparison = a.service_type.localeCompare(b.service_type);

          // If service_type is the same, then sort by carrier_delivery_days in ascending order
          if (serviceTypeComparison === 0) {
            return a.carrier_delivery_days - b.carrier_delivery_days;
          }

          return serviceTypeComparison;
        });

        //console.log("filtered", filteredAndModifiedArray);
        setShipRates(sortedArray); // Setting sortedArray instead of filteredAndModifiedArray
        setLoading(false);
        setError(false)
      }
    } catch (error) {
      //console.error('Error occurred:', error);
      setError(true)
    }

    setLoading(false);
  };
    //console.log("rates---", shipRates);

    const hasValidData = Array.isArray(shipRates) && shipRates.some((data: any) => data.error_messages.length === 0);

    return (
        <>
        {loading && <Loading loadingText="Loading the best local courier rates for you"/>}

        {isError  && (
          <>
          <Card >
      
      <CardContent >
       <p>Oops! An error has occurred.</p>
       <p>Please press the back button to correct your input.</p>
      <Alert severity="error" style={{marginTop:10, marginBottom:10}}>
      {shipErrors?.map((data: any, idx: any) => (
<p>{data.message}</p>
      ))}

      </Alert>
        </CardContent>
        </Card>
          </>
        )}
            {Array.isArray(shipRates) && shipRates.length > 0 && hasValidData && (<Container style={{marginTop:10}}>
    <Card >
      
          <CardContent >
            <Alert severity="info" style={{marginTop:10, marginBottom:10}}>This is just an estimate. The final price may vary depending on parcel dimensions, insurance etc.
Create a <Link href="/auth/login">shipment</Link> to see the full landed cost.</Alert>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Carrier</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Service</TableCell>
            <TableCell>Days</TableCell>
            <TableCell>Charges</TableCell>
            <TableCell align="right">EDD</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {shipRates?.map((data: any, idx: any) => (
            <>
              

              {(data.error_messages.length <1) && (<TableRow key={idx}>
              <TableCell>{data.carrier_friendly_name}</TableCell>
              <TableCell>{getPackageName(data.package_type)}</TableCell>
              <TableCell>{data.service_type}</TableCell>
              <TableCell>{data.delivery_days}</TableCell>
              <TableCell>{`$${Number(data.shipping_amount.amount).toFixed(2)}`}</TableCell>
              {/* <TableCell>{`$${Number(data.shipping_amount.amount + (DEFAULTS.RATEPERCENTAGE * data.shipping_amount.amount)).toFixed(2)}`}</TableCell> */}
              <TableCell align="right">{moment(data.estimated_delivery_date).format('MM/DD/YYYY')}</TableCell>
              <TableCell><Link href="/auth/signup">Ship Now</Link></TableCell>
              </TableRow>)}
              </>
          ))}
        </TableBody>
      </Table>
      
{/* <Typography variant="caption">Discounts off UPS daily rates. Rates are limited to shipping from the U.S. only. Rates and any applicable discounts are subject to change at any time without notice.</Typography> */}
</CardContent>
</Card>
    </Container>
    )}
    </>
    )
})
    export default RatesPanel;