import React , {useState, useEffect} from 'react';
import { useNavigate,  useParams} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import LogoComponent from './LogoComponent'
import CopyRight from './CopyRight'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DEFAULTS } from '../../Constants/Constants'

const theme = createTheme();

type FormError = {
  status: string,
  msg:string
  // Add other properties if needed, for example:
  // message: string;
};

export default function Verify() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formError, setFormError] = useState<FormError>({ status: '', msg:'' });
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   const getRecords = async () => {
  //     try {
  //       const response = await fetch(`${DEFAULTS.API_URL}verify/${id}`, {
  //         method: 'GET',
  //       });
  
  //       const records = await response.json();
  //       console.log('records', records);
  //       setFormError(records);
  //     } catch (error) {
  //       console.error('Error fetching records:', error);
  //       // Handle errors or set a default error state.
  //       setFormError({ status: 'error', msg: 'Failed to fetch data.' });
  //     }
  //   };
  //   getRecords();
  // },[]);
  const buildApiUrl = (endpoint: any) => `${DEFAULTS.API_URL}${endpoint}`;


  useEffect(() => {
    
    const getRecords = async () => {
      if (id) {
      try {
        const response = await fetch(buildApiUrl(`verify/${id}`), {
          method: 'GET',
        });
        const records = await response.json();
        console.log('records', records);
        setFormError(records);
      } catch (error) {
        console.error('Error fetching records:', error);
        setFormError({ status: 'error', msg: 'Failed to fetch data.' });
      } finally {
        setLoading(false);
      }
    } else{
      setFormError({ status: 'error', msg: 'Failed to fetch data. Please try again by clicking the link in the email.' });
    }
    };
    getRecords();
  },[id,loading]);



  const isSuccess = formError?.status === 'success';
  const isError = formError?.status === 'error';

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://ikeocean.com/wp-content/uploads/2020/05/containers-1.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <LogoComponent /> */}

           {isSuccess && ( 
           <Typography component="h1" variant="h5">{formError?.msg}</Typography>
           )
           }
           {isError && ( 
           <Typography component="h1" variant="h5">{formError?.msg}</Typography>
           )
           }

           <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={()=>{navigate('/auth/login')}}

              >
                Login
              </Button>


              {/* <CopyRight sx={{ mt: 5 }} /> */}
          </Box>

        </Grid>
      </Grid>
    </ThemeProvider>
  );
}