import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { fetchProfileData, ProfileData } from '../../services/api';

export interface ProfileState {
  value: ProfileData | {}; // Use null instead of an empty object
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ProfileState = {
  value: {}, // Initialize with null
  status: 'idle',
};

export const fetchProfile = createAsyncThunk('profile/fetchProfile', async () => {
  try {
    const response = await fetchProfileData();
    return response.data; // Assuming your API response contains the profile data
  } catch (error) {
    throw error;
  }
});

export const profileSlice = createSlice({
  name: 'customerProfile',
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<ProfileData>) => {
      state.value = action.payload;
    },
    getProfile: (state) => {
      state.value = state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfile.fulfilled, (state, action: PayloadAction<ProfileData>) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { updateProfile, getProfile } = profileSlice.actions;
export const selectProfile = (state: RootState) => state.profile.value;

export default profileSlice.reducer;
