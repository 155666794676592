import React ,{useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route, Link, Outlet, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Grid version 1
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Logo from './assets/images/logo.png';
import Header from './portal/components/Common/Header/Header'
import Navigation from './portal/components/Common/Navigation/Navigation'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CopyRight from './auth/Login/CopyRight'
import {DEFAULTS} from './Constants/Constants'
import ReactGA from 'react-ga'
import './App.css';
ReactGA.initialize(DEFAULTS.GA_TRACKING_ID)

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#0a0800',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#0a0800',
    },
  },
});

function App() {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
      const userToken = localStorage.getItem('user-token');
      if (!userToken || userToken === 'undefined') {
          setIsLoggedIn(false);
      }
      setIsLoggedIn(true);
  }
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
      checkUserToken();
  }, [isLoggedIn,location]);
  return (
    <div style={{height: '100vh'}}>
<ThemeProvider theme={theme}>
    {isLoggedIn && <Header window={window}/>}
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={2} md={2} style={{height: '95vh'}} sx={{ display: { xs: 'none', sm: 'block' , height: '100vh'} }}>
    {isLoggedIn && <Navigation />}
    </Grid>
    <Grid item xs={12} md={10} style={{backgroundColor: '#f4f6f8', marginTop:'10px', paddingBottom:'20px'}}>
    <Outlet/>
    </Grid>
    </Grid>
    {isLoggedIn && <CopyRight sx={{ display: { xs: 'block', sm: 'block'} }} />}
</ThemeProvider>
</div>

  );
}

export default App;
