import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Modal from '@mui/material/Modal';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import HouseIcon from '@mui/icons-material/House';
import Autocomplete from '@mui/material/Autocomplete';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AnchorIcon from '@mui/icons-material/Anchor';
import { DEFAULTS } from '../../../../Constants/Constants'
import CloseIcon from '@mui/icons-material/Close';
import { Container, Divider, Card, CardContent, Alert, RadioGroup, FormControlLabel, Radio,LinearProgress, TextField, Button, Typography, Box } from '@mui/material';
interface TitleProps {
  children?: React.ReactNode;
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const splitAddressComponents = (addressComponents: any) => {
    const result: { [key: string]: string } = {}; // Specify the type for 'result'
  
    addressComponents.forEach((component: any) => {
      const types: string[] = component.types; // Specify the type for 'types'
      types.forEach((type: string) => {
        if (!result[type]) {
          result[type] = component.short_name;
        }
      });
    });
  
    return result;
  };
  interface FinderProps {
    data?: {
      headingText?: string;
      shipType?: string;
      shipField?:string;
      // Add other properties of the object here
    };
    onCloseModal: (dataFromModal: any,dataLabel: any, dataType:any, dataShip: any) => void;
    // Define other props here if needed
  }
  
  const Finder: React.FC<FinderProps> = ({ data, onCloseModal  }) => {

    const [alignment, setAlignment] = React.useState('');
    const [autoLabel, setAutoLabel] = React.useState('');
    const [autoAddType, setAutoAddType] = React.useState('');
    const [isSelected, setSelected] = React.useState(false);
    const [autoData, setAutoData] = React.useState<Record<string, any>>({});
       const handleChange = (
         event: React.MouseEvent<HTMLElement>,
         newAlignment: string,
       ) => {
        console.log(" this is oiaoooa",newAlignment )
         setAlignment(newAlignment);
         if (newAlignment !== null){ console.log("alasasasas",newAlignment)
          setSelected(true)} else{setSelected(false)
        }
         setAutoLabel('')
         setAutoData({})

       };

  return (
    <>
    <Modal
  open={true}
  onClose={(event, reason) => {
    if (reason !== 'backdropClick') {
      onCloseModal(null,null,null, null);
    }
  }}
  //onClose={onClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {data?.headingText}
      </Typography>
      <Button onClick={()=>{onCloseModal(null,null,null,null)}} variant='text'><CloseIcon /></Button>
    </div>
   
  <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      fullWidth
      sx={{
        "& .MuiToggleButton-root": {
         
          color: "black",
          "&.Mui-selected": {
            color: "red",
           
          },
        },
        "& .MuiToggleButton-label": {
          color: "white",
        },
      }}
    >
      {(data?.shipType === 'air') && <ToggleButton value="airport"><FlightIcon /> Airport</ToggleButton>}
      {(data?.shipType === 'ocean') && <ToggleButton value="port"><AnchorIcon /> Port</ToggleButton>}
      {(data?.shipType === 'truck') && <ToggleButton value="terminal"><WarehouseIcon /> Terminal</ToggleButton>}
      <ToggleButton value="door"><HouseIcon /> Door</ToggleButton>

     
    </ToggleButtonGroup>
      <div style={{marginTop:20 , marginBottom:20}}>
    {/* {((alignment ==='airport') || (alignment ==='port') ||(alignment ==='terminal')) && <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={DEFAULTS.AIRPORTS}
      getOptionLabel={(option) => option.name}
      sx={{ width: 300 }}
      onChange={(event, newValue) => {
        if (newValue !== null) {
          console.log(newValue);
          setAutoData(newValue);
          setAutoAddType(alignment);
          setAutoLabel(`${newValue.name} ,${newValue.city}, ${newValue.country} -${newValue.iata_code}`);
        } // Check if the selected value is logged
      }}
      renderInput={(params) => <TextField {...params} size='small'/>}
    />} */}
    {/* {(alignment ==='door') &&  */}
    <GooglePlacesAutocomplete
              apiKey="AIzaSyARY9vCtQv9W6FJjC9sb3xbtJeLC3hrtsE"
               selectProps={{
                onChange: (place: any) => {
                    console.log("place >>>>", place)
                    setAutoLabel(place.value.description)
                    setAutoAddType(alignment)
                    geocodeByPlaceId(place.value.place_id)            
                    .then(results => {
                      setAutoData(splitAddressComponents(results[0].address_components))
                      console.log("adressshipfrom",splitAddressComponents(results[0].address_components), results)
                      const { postal_code,
                        country,
                        administrative_area_level_1,
                        locality,
                        route,
                        street_number

                      } = splitAddressComponents(results[0].address_components);
console.log("autoData", autoData)
                    })
                    .catch(error => console.error(error));
                },
                value: {
                  label: autoLabel, // Use label property
                  value: '', // Use value property
                }
              }}
            // Use onSelect to get the selected place
            /> 
</div>
{(autoLabel !=='') && <div style={{marginTop:10}}>
 

<p>Address : {autoData?.street_number} {autoData?.administrative_area_level_1}</p>
<p>City : {autoData?.locality} </p>
<p>Zip : {autoData?.postal_code}</p>
<p>Country : {autoData?.country}</p>
<Divider />
<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', paddingTop: '5px' }}>
  <Button
    variant="outlined"
    sx={{ mt: 3, mb: 2 }}
    onClick={()=>{onCloseModal(null,null,null, null)}}
  >
    Cancel
  </Button>
  <Button
    variant="contained"
    sx={{ mt: 3, mb: 2 }}
    disabled={!isSelected}
    onClick={()=>{onCloseModal(autoData, autoLabel, autoAddType,data?.shipField)}}
  >
    Continue
  </Button>
</div>
{!isSelected && <span style={{color:'red' , fontSize:'9pt'}}>Please select an address type</span>}
</div>
    
            }    
  </Box>
</Modal>
    </>
  );
}

export default Finder;