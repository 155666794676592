import React , {useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {Alert, Grid} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LogoComponent from './LogoComponent'
import CopyRight from './CopyRight'
import axios from 'axios';
import { DEFAULTS } from '../../Constants/Constants'

const theme = createTheme();

const validationSchema = yup.object({
  password: yup
  .string()
  .min(9, 'Enter a valid password')
  .max(20, 'Enter a valid password')
  .required('Enter a password'),
  passwordnew: yup
  .string()
  .min(9, 'Enter a valid password')
  .max(20, 'Enter a valid password')
  .required('Enter a password')
  .oneOf([yup.ref('password'), ''], 'Passwords must match'),
  });

export default function ResetPassword() {
  const navigate = useNavigate();
  let { id } = useParams();
  const[showAlert, setAlert] = useState(false);
  const[status, setStatus] = useState('');
  const[key, setKey] = useState('');

  const getKey = async () => {

    await axios.post(DEFAULTS.API_URL + 'verify-reset', "hello",
    {headers: {
      'Authorization': `Bearer ${id}`
    }})
    .then(
      response => {
        console.log(response)
        setKey(response.data.status)
      }
    )
      
  }
  getKey()
    const message = {
    success: {
      text:"Your password has been reset successfully "},
    error:{
      text:"Error occured during reset. Please try after sometime."
    }
  }
  const formik = useFormik({

    initialValues: {
        password: '',
        passwordnew: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     console.log("values", values)
     const expensesListResp = async () => {

        await axios.post(DEFAULTS.API_URL + 'profile/reset', values,
        {headers: {
          'Authorization': `Bearer ${id}`
        }})
        .then(
          response => {
            console.log(response)
            setStatus(response.data.status)

          }
        )
          
      }
      expensesListResp()
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: 'url(https://ikeocean.com/wp-content/uploads/2020/05/png-Road-Cargo.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <LogoComponent /> */}
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
{ key=="success" &&
            <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                type="password"
                fullWidth
                id="password"
                label="New password"
                name="password"
                autoComplete="password"
                autoFocus
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
             <TextField
                margin="normal"
                fullWidth
                id="passwordnew"
                type="password"
                label="Confirm password"
                name="passwordnew"
                autoComplete="passwordnew"
                autoFocus
                value={formik.values.passwordnew}
                onChange={formik.handleChange}
                error={formik.touched.passwordnew && Boolean(formik.errors.passwordnew)}
                helperText={formik.touched.passwordnew && formik.errors.passwordnew}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{backgroundColor:'#eb4034'}}

              >
                Reset Password
              </Button>
              { showAlert && (
                <Alert severity={(status === 'success')? 'success': 'error'}>{(status === 'success')? message.success.text: message.error.text}</Alert>
              )}

            </Box>
}
{ key=="error" &&
<> <Alert severity='error'>Something went wrong. Please try after some time</Alert>
<ul>
  <li> Please try the reset link received on your email once again</li>
  <li> Your reset link might have expired please request again by clicking the forgot password link below</li>
</ul>
</>

}
<Grid container>
                <Grid item xs>
                  <Link href="/forgot" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/auth/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              {/* <CopyRight sx={{ mt: 5 }} /> */}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}