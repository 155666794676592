import React, {useState, useEffect} from 'react';
import {Container, Card, CardContent, Alert, RadioGroup, FormControlLabel, Radio, Button, Typography, Box} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useSelector } from 'react-redux'
import { getDate } from '../../../../util/DisplayUtils';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import trackData from '../../../../data/tracking.json'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getDateTime } from '../../../../util/DisplayUtils';
import { updateOrder,
  getOrder,
  selectShipmentOrder } from '../../../../features/shipment/shipmentSlice'
  import { DEFAULTS } from '../../../../Constants/Constants'
type shipmentOrderType = {
  [key: string]: any;
}

interface EventData {
  description: string;
  occurred_at: Date; // Make sure occurred_at is of type Date
}

interface TrackData {
  events: EventData[];
}

function ShipmentDetail() {
    const { id } = useParams();
    const [shipmentOrder, setShipmentOrder] = useState<{[key: string]: any}>({});
    const [shipmentOrderFull, setShipmentOrderFull] = useState<{[key: string]: any}>({});
    const [trackData, setTrackData] = useState<{[key: string]: any}>({});
    const token = localStorage.getItem('user-token');
    let navigate = useNavigate();
    
    const getShipment = async () => {
    await axios.post(DEFAULTS.API_URL+`shipment/${id}`,'',
    {headers: {
      'Authorization': `Bearer ${token}`
    }})
    .then(
      response => {
        console.log(response)
        if (response.data.shipmentId)
        {
            setShipmentOrder(response.data.shipmentOrderRecord.shipmentOrder)
            setShipmentOrderFull(response.data)
            console.log("order",response.data)
            if (response.data.shipmentStatus ==='COMPLETE') {
              //getTracking()
            }

            
        }
      }
    )
      
  }



  const getTracking = async () => {
    let trackingParams = {
      trackingNumber: shipmentOrderFull.shipmentLabel.tracking_number,
      carrierCode: shipmentOrderFull.shipmentLabel.carrier_code
  }

    // await axios.post(DEFAULTS.API_URL+`tracking/${carrier_code}/${tracking_number}`,'',
     await axios.post(DEFAULTS.API_URL+'tracking',trackingParams)
     .then(
       response => {
         console.log(response)
         if (response.data)
         {
 setTrackData(response.data)
             
         }
       }
     )
       
   }
  useEffect(() => {

  getShipment()
  

  },[])

  return (
    <Container>
      <Typography variant='h5' align="left" display={'inline'}>Shipment Order  : {id}</Typography>
      <Box>
        <Card>
          <CardContent>

          <Grid container spacing={6} style={{marginTop:10, marginBottom:10}}>
          <Grid item xs={12} md={4}> Order No # : {id}</Grid>
          <Grid item xs={12} md={4}> Order Date # : {getDate(shipmentOrderFull.shipmentOrderDate)}</Grid>
          <Grid item xs={12} md={4}> Status : {shipmentOrderFull.shipmentStatus}</Grid>
</Grid>
    <Divider />
            <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
             Shipment Details
            {shipmentOrder?.rate ? (
                  <>
                  <ul style={{listStyle:'none', paddingLeft:0}}>
                  <li>Carrier Name : {shipmentOrder.rate.carrierFriendlyName} </li>
                  <li>Ship Date :  {getDate(shipmentOrder.rate.shipDate)}</li>
                  <li>Estimated Delivery Date :  {getDate(shipmentOrder.rate.estimatedDeliveryDate)} </li>
                  <li> Service Type:  {shipmentOrder.rate.serviceType} </li>
                  <li>Guranteed Service:  {shipmentOrder.rate.guaranteedService? 'Yes': 'No'} </li>
                  <li> Trackable:  {shipmentOrder.rate.trackable? 'Yes': 'No'} </li>
                  </ul>
                  </>
            ):null}
              </Grid>

              <Grid item xs={12} md={3}>
                Ship To
                {shipmentOrder?.shipments?.ship_to ? (
                  <div> 
                    <ul style={{listStyle:'none', paddingLeft:0}}>
                      <li>{shipmentOrder?.shipments.ship_to.name}</li>
                      <li>{shipmentOrder?.shipments.ship_to.address_line1}</li>
                      <li>{shipmentOrder?.shipments.ship_to.city_locality}</li>
                      <li>{shipmentOrder?.shipments.ship_to.postal_code}</li>
                      <li>{shipmentOrder?.shipments.ship_to.state_province}, {shipmentOrder?.shipments.ship_to.country_code}</li>

                      <li>{shipmentOrder?.shipments.ship_to.phone}</li>
                    </ul>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} md={3}>
                Ship From
                {shipmentOrder?.shipments?.ship_from ? (
                  <div>
                    <ul style={{listStyle:'none', paddingLeft:0}}>
                      <li>{shipmentOrder?.shipments.ship_from.name}</li>
                      <li>{shipmentOrder?.shipments.ship_from.address_line1}</li>
                      <li>{shipmentOrder?.shipments.ship_from.city_locality}</li>
                      <li>{shipmentOrder?.shipments.ship_from.postal_code}</li>
                      <li>{shipmentOrder?.shipments.ship_from.state_province}, {shipmentOrder?.shipments.ship_from.country_code}</li>

                      <li>{shipmentOrder?.shipments.ship_from.phone}</li>
                    </ul>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} md={3}>
                Return To
                {shipmentOrder?.shipments?.return_to ? (
                  <div>
                    <ul style={{listStyle:'none', paddingLeft:0}}>
                      <li>{shipmentOrder?.shipments.return_to.name}</li>
                      <li>{shipmentOrder?.shipments.return_to.address_line1}</li>
                      <li>{shipmentOrder?.shipments.return_to.city_locality}</li>
                      <li>{shipmentOrder?.shipments.return_to.postal_code}</li>
                      <li>{shipmentOrder?.shipments.return_to.state_province}, {shipmentOrder?.shipments.return_to.country_code}</li>

                      <li>{shipmentOrder?.shipments.return_to.phone}</li>
                    </ul>
                  </div>
                ) : null}
              </Grid>
              <Divider/>
              
       {(shipmentOrderFull.shipmentStatus ==='COMPLETE')? (      
                <Grid item xs={12} md={12}> 
                <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>Package Id</TableCell>
            <TableCell>Dimensions (L X W X H) </TableCell>
            <TableCell>Weight</TableCell>
            <TableCell>Tracking Number </TableCell>
            <TableCell>Shipping Labels</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>   
                {
  shipmentOrderFull?.shipmentLabel?.packages?.map((orderPack: any, idx: any) => (
    <TableRow>
    <TableCell>{orderPack.package_id}</TableCell>
    <TableCell>{orderPack.dimensions.length} X {orderPack.dimensions.width} x {orderPack.dimensions.height} {orderPack.dimensions.unit.toUpperCase()}</TableCell>
    <TableCell>{orderPack.weight.value} {orderPack.weight.unit.toUpperCase()}</TableCell>
    <TableCell>{orderPack.tracking_number}</TableCell>
    <TableCell><a target="_blank" href={shipmentOrderFull?.shipmentLabel?.label_download.pdf}><PictureAsPdfIcon /></a>
    <a target="_blank" href={shipmentOrderFull?.shipmentLabel?.label_download.zpl}><PrintIcon /></a>
    <a target="_blank" href={shipmentOrderFull?.shipmentLabel?.label_download.png}><ImageIcon /></a></TableCell>

</TableRow>
  )
  )
   
  }
  </TableBody>
      </Table>
  </Grid>
):''}

              <Grid item xs={12} md={10}>
                {shipmentOrder?.rate ? (
                  <>

                  { (shipmentOrder.rate.warningMessages.length > 0) ? (
                    
                    <Alert color='warning'>
                    {shipmentOrder.rate.warningMessages.map((warning: any, index: any) => (
                     <p>{warning}</p>
                    ))}
                    </Alert>
                  ) : null}

                  </>

                ): null}
              </Grid>
              <Grid item xs={12} md={2}>
                <ul style={{listStyle:'none', paddingLeft:0}}>
    <li> Charges : $ {Number(shipmentOrderFull.shipmentFinalAmount).toFixed(2)} </li>
    <li> Insurance : $ {Number(shipmentOrderFull.shipmentFinalInsurance).toFixed(2)}</li>
    <li> Others : $ {Number(shipmentOrderFull.shipmentFinalOther).toFixed(2)}</li>
    <li> Total : $ {Number(shipmentOrderFull.shipmentTotal).toFixed(2)}</li>
    <li> Georgia Tax : $ {Number(shipmentOrderFull.shipmentTax).toFixed(2)}</li>
    <li> Grand Total : $ {Number(shipmentOrderFull.shipmentGrandTotal).toFixed(2)}</li>
    </ul>

    </Grid>

            </Grid>
    
        <Divider />
        {(shipmentOrderFull.shipmentStatus ==='COMPLETE')? ( 
          <>
        <Button 
         onClick={getTracking}
         variant="contained"
         style={{marginTop:10, marginBottom:10}}
         
         >Track your package</Button>
    </>) : (
      <Alert severity="error">Shipping information unavailable as order is INCOMPLETE</Alert>
    )}

      {(trackData.events && trackData.events.length> 0) &&
      <>
      {(trackData.status_code === 'EX' || trackData.status_code === 'UN') ? (
          <>
          {trackData.exception_description}
          </>
           ) : (
            <>
            <p>Tracking Number : {trackData.tracking_number}</p>
            <p>Carrier Code : {trackData.carrier_code.toUpperCase()}</p>
            <p>Status : {trackData.status_description}</p>
            <p>Description:{trackData.carrier_status_description}</p>

          

          {trackData.events.sort((a: any, b: any) => {
        return Date.parse(a.occurred_at) - Date.parse(b.occurred_at);
    }).map((eventData: any, index: any) => (
                     <p>{getDateTime(eventData.occurred_at)} - {eventData.description}</p>
                    ))}
                    </>
                    
           )
    } 
      </>
      }


{(trackData.errors && trackData.errors.length> 0) &&
 <Alert severity="error">{trackData.errors[0].message}</Alert>
}
          </CardContent>
        </Card>

      </Box>
    </Container>
  );
}

export default ShipmentDetail;
