import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField, Button, InputLabel, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULTS } from '../../Constants/Constants'
import { getPackageName } from '../../util/DisplayUtils';
import { firebaseApp, analytics } from "../../lib/firebase";
import { logEvent } from "firebase/analytics"
type MyState = Array<any> | Record<string, any>;
interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}
interface sortedData {
}

interface MyType {
  service_code: string;
  service_type: string;
  carrier_delivery_days: number;
  error_messages: string[];
  shipping_amount: {
    amount: number;
    // Other properties related to shipping_amount
  };
  // Other properties
}

interface RateType {
  service_code: string;
  mark_up: string;
  // Other properties related to rates
}
const validationSchema = yup.object({
    shipToZip: yup
    .string()
    .min(3, 'Zipcode should be 4-6 characters length')
    .max(6, 'Zipcode should 6 characters length')
    .required('Enter a destination zip code'),
    shipFromZip: yup
    .string()
    .min(3, 'Zipcode should be 4-6 characters length')
    .max(6, 'Zipcode should 6 characters length')
    .required('Enter a origination zip code'),
    weight: yup
    .number()
    .min(0.01, 'Weight should be greater or equal to 0.01')
    .max(250, 'Weight should be less than or equal 250')
    .required('Enter weight'),
    height: yup
    .number()
    .min(1, 'Height should be greater or equal to 1')
    .max(150, 'Height should be less than or equal 150')
    .required('Enter height'),
    width: yup
    .number()
    .min(1, 'Width should be greater or equal to 1')
    .max(150, 'Weight should be less than or equal 150')
    .required('Enter width'),
    length: yup
    .number()
    .min(1, 'Length should be greater or equal to 1')
    .max(150, 'Length should be less than or equal 150')
    .required('Enter length'),
  });

function Quote() {
  const [shipRates, setShipRates] = useState<{[key: string]: any}>({});
    const [shipErrors, setShipError] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [isError, setError] = React.useState(false);
    const countries: CountryType[] = DEFAULTS.COUNTRIES.sort((a: any, b: any) =>a.label.localeCompare(b.label));
    const formbody : any = {
        "carrier_ids": DEFAULTS.CARRIERS,
        "from_country_code": "",
        "from_postal_code": "000",
        "to_country_code": "",
        "to_postal_code": "000",
        "to_city_locality": "",
        "to_state_province": "",
        "weight": {
          "value": 1.0,
          "unit": "ounce"
        },
        "dimensions": {
          "unit": "inch",
          "length": 5.0,
          "width": 5.0,
          "height": 5.0
        },
        "confirmation": "none",
        "address_residential_indicator": "no"
      }
      const formik = useFormik({
        initialValues: {
          shipToZip: '',
          shipFromZip: '',
          weight:1.0,
          unitWeight:"ounce",
          shipToCountry:"",
          shipFromCountry:"",
          length:5,
          width:5,
          height:5,
          unitDimension:"inch"
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
         console.log("values", values)
         logEvent(analytics, 'Quote', {
          content_type: 'widget',
          content_id: 'quote'
          });
         formbody.from_postal_code= values.shipFromZip;
         formbody.to_postal_code= values.shipToZip;
         formbody.from_country_code=values.shipFromCountry;
         formbody.to_country_code=values.shipToCountry;
         formbody.weight.value=values.weight;
         formbody.weight.unit=values.unitWeight;
         formbody.dimensions.length=values.length;
         formbody.dimensions.width=values.width;
         formbody.dimensions.height=values.height;
         formbody.dimensions.unit=values.unitDimension;
         console.log("formbody", formbody)
         getRates()
        },
      }); 

      const getRates1 = async () => {
  setLoading(true);
  
  try {
    const response = await axios.post(DEFAULTS.API_URL + 'estimate', formbody);
    
    if (response.status === 500) {
      console.error('Internal server error occurred');
      setError(true)
    } else {
      const myArray: MyType[] = response.data;
      const rates: RateType[] = DEFAULTS.MARKUP;
      
      const filteredAndModifiedArray = myArray
        .filter(item => item.error_messages.length === 0)
        .map(item => {
          const rate = rates.find(rateItem => rateItem.service_code === item.service_code);

          if (rate) {
            const modifiedAmount = item.shipping_amount.amount * Number(rate.mark_up);
            
            return {
              ...item,
              shipping_amount: {
                amount: modifiedAmount,
                // Other properties related to shipping_amount
              },
            };
          }
          return item; // If no rate is found, leave the item unchanged
        });
      
      // Step 2: Sort the filtered and modified array
      const sortedArray = filteredAndModifiedArray.sort((a, b) => {
        // First, sort by service_type in ascending order
        const serviceTypeComparison = a.service_type.localeCompare(b.service_type);
        
        // If service_type is the same, then sort by carrier_delivery_days in ascending order
        if (serviceTypeComparison === 0) {
          return a.carrier_delivery_days - b.carrier_delivery_days;
        }
        
        return serviceTypeComparison;
      });
      
      console.log("filtered", filteredAndModifiedArray);
      setShipRates(sortedArray); // Setting sortedArray instead of filteredAndModifiedArray
    }
  } catch (error) {
    console.error('Error occurred:', error);
  }
  
  setLoading(false);
};
    

const getRates = async () => {
  setLoading(true);
  setError(false)
  
  try {
    const response = await axios.post(DEFAULTS.API_URL + 'estimate', formbody);
    
    if (response.status === 500) {
      console.error('Internal server error occurred');
      } else {
      const myArray: MyType[] = response.data;
      const rates: RateType[] = DEFAULTS.MARKUP;
      
      const filteredAndModifiedArray = myArray
        .filter(item => item.error_messages.length === 0)
        .map(item => {
          const rate = rates.find(rateItem => rateItem.service_code === item.service_code);

          if (rate) {
            const modifiedAmount = item.shipping_amount.amount * Number(rate.mark_up);
            
            return {
              ...item,
              shipping_amount: {
                amount: modifiedAmount,
                // Other properties related to shipping_amount
              },
            };
          }
          return item; // If no rate is found, leave the item unchanged
        });
      
      // Step 2: Sort the filtered and modified array
      const sortedArray = filteredAndModifiedArray.sort((a, b) => {
        // First, sort by service_type in ascending order
        const serviceTypeComparison = a.service_type.localeCompare(b.service_type);
        
        // If service_type is the same, then sort by carrier_delivery_days in ascending order
        if (serviceTypeComparison === 0) {
          return a.carrier_delivery_days - b.carrier_delivery_days;
        }
        
        return serviceTypeComparison;
      });
      
      console.log("filtered", filteredAndModifiedArray);
      setShipRates(sortedArray); // Setting sortedArray instead of filteredAndModifiedArray
    }
  } catch (error) {
    console.error('Error occurred:', error);
    setError(true)
  }
  
  setLoading(false);
};
       useEffect(() => {
         // Update the document title using the browser API
         //expensesListResp();
     
       });

    return (
      <div>
        
        <Container>
        <Typography variant="h4" style={{color:'#eb4034'}}>Get a Quote</Typography>
            <Card variant="outlined" style={{marginBottom:10}}>
                <CardContent>
 {/* { (shipErrors === null) ? '':
 <Alert severity="error" style={{marginBottom:10}}>
  <AlertTitle>Error</AlertTitle> 
  This is an error alert — <strong>check it out!</strong>
</Alert> } */}
        
        <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                <InputLabel id="shipFromCountryLabel">From</InputLabel>
                <Select
                      labelId="shipFromCountryLabel"
                      id="shipFromCountry"
                      name="shipFromCountry"
                      size="small"
                      label="From"
                      value={formik.values.shipFromCountry}
                      onChange={formik.handleChange}
                    >
                       {countries.map((country: any, idx: any) => (
                      <MenuItem value={country.code}>{country.label}</MenuItem>
                       ))}

                    </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} sm={4}>
                <TextField
                    autoComplete="shipFromZip"
                    name="shipFromZip"
                    fullWidth
                    id="shipFromZip"
                    size="small"
                    autoFocus
                    label="Zip Code"
                    value={formik.values.shipFromZip}
                    onChange={formik.handleChange}
                    error={formik.touched.shipFromZip && Boolean(formik.errors.shipFromZip)}
                    helperText={formik.touched.shipFromZip && formik.errors.shipFromZip}
                  />
                </Grid>
                <Grid item xs={12} sm={4} />
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                  <InputLabel id="shipToCountryLabel">To</InputLabel>
                  <Select
                      labelId="shipToCountryLabel"
                      id="shipToCountry"
                      name="shipToCountry"
                      size="small"
                      label="To"
                      value={formik.values.shipToCountry}
                      onChange={formik.handleChange}
                    >
                       {countries.map((country: any, idx: any) => (
                      <MenuItem value={country.code}>{country.label}</MenuItem>
                       ))}

                    </Select>
                   
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    autoComplete="shipToZip"
                    name="shipToZip"
                    fullWidth
                    id="shipToZip"
                    size="small"
                    autoFocus
                    label="Zip Code"
                    value={formik.values.shipToZip}
                    onChange={formik.handleChange}
                    error={formik.touched.shipToZip && Boolean(formik.errors.shipToZip)}
                    helperText={formik.touched.shipToZip && formik.errors.shipToZip}
                  />
                </Grid>
                <Grid item xs={12} md={4}/>
                <Grid item xs={6} sm={4}>
                  <TextField
                    autoComplete="given-name"
                    name="weight"
                    fullWidth
                    id="weight"
                    size="small"
                    autoFocus
                    label="Parcel Weight"
                    value={formik.values.weight}
                    onChange={formik.handleChange}
                    error={formik.touched.weight && Boolean(formik.errors.weight)}
                    helperText={formik.touched.weight && formik.errors.weight}
                  />

                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl fullWidth>
                  <InputLabel id="weight-label">Weight in</InputLabel>
                    <Select
                      id="unitWeight"
                      labelId="weight-label"
                      name="unitWeight"
                      size="small"
                      label="Weight in"
                      value={formik.values.unitWeight}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="gram">Gram</MenuItem>
                      <MenuItem value="kilogram">Kilogram</MenuItem>
                      <MenuItem value="pound">Pound</MenuItem>
                      <MenuItem value="ounce">Ounce</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}/>
                <Grid item xs={4} sm={2}>
                  <TextField
                    name="length"
                    label="Length"
                    id="length"
                    size="small"
                    autoFocus
                    value={formik.values.length}
                    onChange={formik.handleChange}
                    error={formik.touched.length && Boolean(formik.errors.length)}
                    helperText={formik.touched.length && formik.errors.length}
                  />
                                  </Grid>
                <Grid item xs={4} sm={2}>
                  <TextField
                    name="width"
                    label="Width"
                    id="width"
                    size="small"
                    autoFocus
                    value={formik.values.width}
                    onChange={formik.handleChange}
                    error={formik.touched.width && Boolean(formik.errors.width)}
                    helperText={formik.touched.width && formik.errors.width}
                  />
                                                    </Grid>
                <Grid item xs={4} sm={2}>
                  <TextField
                    name="height"
                    id="height"
                    size="small"
                    autoFocus
                    label="Height"

                    value={formik.values.height}
                    onChange={formik.handleChange}
                    error={formik.touched.height && Boolean(formik.errors.height)}
                    helperText={formik.touched.height && formik.errors.height}
                  />

                </Grid>
                <Grid item xs={4} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel id="dimension-label">Dimensions in</InputLabel>
                    <Select
                      labelId="dimension-label"
                      id="unitDimension"
                      name="unitDimension"
                      size="small"
                      label="Dimensions in"
                      value={formik.values.unitDimension}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="inch">Inch</MenuItem>
                      <MenuItem value="centimeter">Cm</MenuItem>

                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{backgroundColor:'#eb4034', marginTop:20, marginBottom:20}}
                disabled={loading}
              >
                Get Quote
              </Button>
              </form>
              {isError && <Alert severity="error" style={{marginTop:10, marginBottom:10}}> 
              Error occured. Please try again                 
              </Alert>
              } 


              {(shipRates?.errors?.length > 0) && (
              <Alert severity="error" style={{marginTop:10, marginBottom:10}}> 
                {shipRates?.errors.map((data: any, idx: any) => (<>
                  {data.message}. Try again with a correct postal code.
                </>))}
              </Alert>
              )} 

{(shipRates[0]?.error_messages.length > 0) && (
                 <Alert severity="error" style={{marginTop:10, marginBottom:10}}>{shipRates[0].error_messages[0]}</Alert>)}
              </CardContent>
              </Card>

        </Container>
        {loading && (
          <Container style={{minHeight:100}}>
            
            <p style={{minHeight:100, textAlign:'center'}}><CircularProgress/><br/> Loading best rates for you</p>
            </Container>
        )}
 {(shipRates.length > 0 && shipRates[0].error_messages.length === 0) && (<Container style={{marginTop:10}}>
      <Card >
        
              <CardContent >
              <Alert severity="info" style={{marginTop:10, marginBottom:10}}>This is just an estimate. The final price may vary depending on parcel dimensions, insurance etc.
Create a <Link href="/auth/login">shipment</Link> to see the full landed cost.</Alert>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Carrier</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Days</TableCell>
              <TableCell>Charges</TableCell>
              <TableCell align="right">EDD</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {shipRates?.map((data: any, idx: any) => (
              <>
                
  
                {(data.error_messages.length <1) && (<TableRow key={idx}>
                <TableCell>{data.carrier_friendly_name}</TableCell>
                <TableCell>{getPackageName(data.package_type)}</TableCell>
                <TableCell>{data.service_type}</TableCell>
                <TableCell>{data.delivery_days}</TableCell>
                <TableCell>{`$${Number(data.shipping_amount.amount).toFixed(2)}`}</TableCell>
                {/* <TableCell>{`$${Number(data.shipping_amount.amount + (DEFAULTS.RATEPERCENTAGE * data.shipping_amount.amount)).toFixed(2)}`}</TableCell> */}
                <TableCell align="right">{moment(data.estimated_delivery_date).format('MM/DD/YYYY')}</TableCell>
                <TableCell><Link href="/auth/signup">Ship Now</Link></TableCell>
                </TableRow>)}
                </>
            ))}
          </TableBody>
        </Table>
        
{/* <Typography variant="caption">Discounts off UPS daily rates. Rates are limited to shipping from the U.S. only. Rates and any applicable discounts are subject to change at any time without notice.</Typography> */}
</CardContent>
</Card>
      </Container>
      )}
      </div>
    );
  }



  export default Quote;