import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Link, Outlet} from "react-router-dom";
import './index.css';
import App from './App';
import Auth from './auth/Auth';
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from './util/ProtectedRoute'
import Shipment from './portal/components/pages/Shipment/Shipment'
import ShipmentInfo from './portal/components/pages/Shipment/ShipmentInfo'
import CourierInfo from './portal/components/pages/Shipment/CourierInfo'
import PaymentInfo from './portal/components/pages/Shipment/PaymentInfo'
import Confirmation from './portal/components/pages/Shipment/Confirmation'
import AddOns from './portal/components/pages/Shipment/AddOns'
import Quote from './portal/components/pages/Quote/Quote'
import Quotes from './portal/components/pages/Quote/Quotes'
import QuoteDetail from './portal/components/pages/Quote/QuoteDetail'
import Shipments from './portal/components/pages/Shipments/Shipments'
import ShipmentDetail from './portal/components/pages/Shipments/ShipmentDetail'
import Tracking from './portal/components/pages/Shipments/Tracking'
import Profile from './portal/components/pages/Profile/Profile'
import Company from './portal/components/pages/Company/Company'
import Help from './portal/components/pages/Help/Help'
import Wallet from './portal/components/pages/Wallet/Wallet'
import Reports from './portal/components/pages/Reports/Reports'
import PaymentPage from './portal/components/Common/Payment/PaymentPage'
import Dashboard from './portal/components/pages/Dashboard/Dashboard'
import DataLoader from './portal/components/pages/Loading/DataLoader'
import Login from './auth/Login/Login'
import Front from './auth/Login/Front'
import Payment from './auth/Login/Payment'
import AboutUs from './auth/Login/AboutUs'
import ContactUs from './auth/Login/ContactUs'
import QuoteFinal from './auth/Login/QuoteFinal'
import SignUp from './auth/Login/SignUp'
import Home from './auth/Login/Home'
import QuoteAuth from './auth/Login/Quote'
import QuoteNew from './auth/Login/QuoteNew'
import TrackingAuth from './auth/Login/Tracking'
import ConfirmEmail from './auth/Login/ConfirmEmail'
import PaymentSuccess from './portal/components/pages/Dashboard/PaymentSuccess'
import Verify from './auth/Login/Verify'
import ForgotPassword from './auth/Login/ForgotPassword'
import ResetPassword from './auth/Login/ResetPassword'
import { store } from './store'
import { Provider } from 'react-redux'
import Logout from './auth/Login/Logout';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
    <BrowserRouter basename={'/'}>
    <Routes>
        <Route path='/auth' element={<Auth />}>
            <Route path='home' element={<Front />} />
        </Route>
        {/* <Route path='/auth' element={<Auth />}>
            <Route path='/home' element={<Front />} />
            
        </Route> */}
        <Route path='/auth' element={<Auth />}>
            <Route path='login' element={<Login />} />
            
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='pricing' element={<QuoteAuth />} />
            
        </Route>

        <Route path='/auth' element={<Auth />}>
            <Route path='quote' element={<QuoteNew />} />
            
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='tracking' element={<TrackingAuth />} />
            
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='payment' element={<Payment />} />
            
        </Route>

        <Route path='/auth' element={<Auth />}>
            <Route path='about-us' element={<AboutUs />} />
            
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='complete-quote' element={<QuoteFinal />} />
            
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='contact-us' element={<ContactUs />} />
            
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='logout' element={<Logout />} />
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='signup' element={<SignUp />} />
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='confirm-email' element={<ConfirmEmail />} />
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='forgot-password' element={<ForgotPassword />} />
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='reset/:id' element={<ResetPassword />} />
        </Route>
        <Route path='/auth' element={<Auth />}>
            <Route path='verify/:id?' element={<Verify />} />
        </Route>
        <Route path='' element={<Provider store={store}><App /></Provider>}>
            <Route path='' element={
                <ProtectedRoute>
                    <DataLoader />
                </ProtectedRoute>
            } />
            <Route path='/portal/dashboard' element={
                <ProtectedRoute>
                    <Dashboard />
                </ProtectedRoute>
            } />
             <Route path='/portal/profile' element={
                <ProtectedRoute>
                    <Profile />
                </ProtectedRoute>
            } />
            <Route path='/portal/pay' element={
                <ProtectedRoute>
                    <PaymentPage />
                </ProtectedRoute>
            } />
            <Route path='/portal/wallet' element={
                <ProtectedRoute>
                    <Wallet />
                </ProtectedRoute>
            } />
                <Route path='/portal/company' element={
                <ProtectedRoute>
                    <Company />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipments' element={
                <ProtectedRoute>
                    <Shipments />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipment/:id' element={
                <ProtectedRoute>
                <ShipmentDetail />
                </ProtectedRoute>
            } />
                        <Route path='/portal/payment-success' element={
                <ProtectedRoute>
                <PaymentSuccess />
                </ProtectedRoute>
            } />
            <Route path='/portal/tracking/:carrier_code/:tracking_number' element={
                <ProtectedRoute>
                <Tracking />
                </ProtectedRoute>
            } />
            <Route path='/portal/reports' element={
                <ProtectedRoute>
                    <Reports />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipment/basic' element={
                <ProtectedRoute>
                    <Shipment />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipment/shipment-info' element={
                <ProtectedRoute>
                    <ShipmentInfo />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipment/courier-info' element={
                <ProtectedRoute>
                    <CourierInfo />
                </ProtectedRoute>
            } />
            <Route path='/portal/shipment/add-ons' element={
                <ProtectedRoute>
                    <AddOns />
                </ProtectedRoute>
            } />
            <Route path='/portal/help' element={
                <ProtectedRoute>
                    <Help />
                </ProtectedRoute>
            } />

            <Route path='/portal/loading' element={
                <ProtectedRoute>
                    <DataLoader />
                </ProtectedRoute>
            } />
                        <Route path='/portal/shipment/payment' element={
                <ProtectedRoute>
                    <PaymentInfo />
                </ProtectedRoute>
            } />
                        <Route path='/portal/shipment/complete/:shipmentid' element={
                <ProtectedRoute>
                    <Confirmation />
                </ProtectedRoute>
            } />
            <Route path='/portal/quotes' element={
                <ProtectedRoute>
                    <Quotes />
                </ProtectedRoute>
            } />
            <Route path='/portal/quote/:id/:quoteId' element={
                <ProtectedRoute>
                <QuoteDetail />
                </ProtectedRoute>
            } />
            <Route path='/portal/quote' element={
                <ProtectedRoute>
                    <QuoteNew />
                </ProtectedRoute>
            } />
        </Route>
        
    </Routes>
</BrowserRouter>
 // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
