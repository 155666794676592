import React, { useState } from 'react';
import axios from 'axios';
import { HostedForm, HostedFormDispatchDataResponse } from 'react-acceptjs';
import { useAcceptJs } from 'react-acceptjs';
import { DEFAULTS } from '../../Constants/Constants'
const authDataProd = { //prod
    apiLoginID: '5shgWG8V5j',
    clientKey: '4Xqe2Ar9VCp6cZZy56gfZ7dr5r555KZM7M4BXB32Ta58AgFDdeSbJ9q5cwV2QK84',
    //clientKey: '28u2H8B4VanT3az3',
  };

  const authDataTest = {  
    apiLoginID: '2d4Bq8L4',
    clientKey: '387abB5JbfVP975s6x9qZt4EsVxPS7Nfvp8us7qTWSeWtCVw9Tgk9sQNNmAp34s5',
    //clientKey: '4C6js4w5E228E2mf',
  };


  type BasicCardInfo = {
    cardNumber: string;
    cardCode: string;
    month: string;
    year: string;
  };


const ResponseBlock = ({ response }: { response: string }) => {
  return (
    <>
      <label className="form-label">Response</label>
      <pre
        className="bg-light"
        style={{ width: '100%', height: '210px', marginTop: 0 }}
      >
        <code className="language-json">{response}</code>
      </pre>
    </>
  );
};


const startPayment = async (data: any) => {
  console.log("paymentdata", data)
  await axios.post(DEFAULTS.API_URL + 'payment', data)
  .then(
    response => {
     console.log("paymentdata2", response.data)
    }
  )
    
}


const Payment = () => {
  const [response, setResponse] = React.useState<string>('');
  const handleSubmit = async (hostedFormResponse: HostedFormDispatchDataResponse) => {
    setResponse(JSON.stringify(hostedFormResponse, null, 2));
    await startPayment(hostedFormResponse)
  };

  return <>
  
  <HostedForm 
  authData={authDataProd}
  environment= 'PRODUCTION'
  onSubmit={handleSubmit}
  buttonText= 'Pay Now'
  />
  <ResponseBlock response={response} />
  </>
};


export default Payment;




