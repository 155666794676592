import * as React from 'react';

import Modal from '@mui/material/Modal';
import { Container, Card, CardContent, Alert, RadioGroup, FormControlLabel, Radio,LinearProgress, TextField, Button, Typography, Box } from '@mui/material';
interface TitleProps {
  children?: React.ReactNode;
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export default function Loading({loadingText}: any) {
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <>
    <Modal
  open={open}
  onClose={(event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  }}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      {loadingText}
    </Typography>
    <LinearProgress color="secondary" />
    
  </Box>
</Modal>
    </>
  );
}