import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import {Grid,Card,CardContent,Typography} from '@mui/material'; // Grid version 1
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { DEFAULTS } from '../../../../Constants/Constants'

function Reports() {
  const [shipRates, setShipRates] = useState([])
  const formbody: any = {
    "rate_options": {
      "carrier_ids": [
        "se-4299739"
      ]
    },
    "shipment": {
      "validate_address": "no_validation",
      "ship_to": {
        "name": "Amanda Miller",
        "phone": "555-555-5555",
        "address_line1": "525 S Winchester Blvd",
        "city_locality": "San Jose",
        "state_province": "CA",
        "postal_code": "95128",
        "country_code": "US",
        "address_residential_indicator": "yes"
      },
      "ship_from": {
        "company_name": "Example Corp.",
        "name": "John Doe",
        "phone": "111-111-1111",
        "address_line1": "4009 Marathon Blvd",
        "address_line2": "Suite 300",
        "city_locality": "Austin",
        "state_province": "TX",
        "postal_code": "78756",
        "country_code": "US",
        "address_residential_indicator": "no"
      },
      "packages": [
        {
          "weight": {
            "value": 1.0,
            "unit": "ounce"
          }
        }
      ]
    }
  };


  const expensesListResp = async () => {

    await axios.post('http://api.ikeocean.com/rates.php', formbody)
    .then(
      response => setShipRates(response.data.rate_response.rates)
    )
      
  }
  useEffect(() => {
    // Update the document title using the browser API
    expensesListResp();

  });



    return (
      <div>
        <Container>
        <Typography variant='h4'>Reports</Typography>
            <Card variant="outlined">
                <CardContent>
        <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Carrier</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Service</TableCell>
            <TableCell>Days</TableCell>
            <TableCell>Charges</TableCell>
            <TableCell align="right">EDD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shipRates?.map((data: any, idx: any) => (
            <TableRow key={idx}>
              <TableCell>{data.carrier_nickname}</TableCell>
              <TableCell>{data.carrier_code}</TableCell>
              <TableCell>{data.service_type}</TableCell>
              <TableCell>{data.delivery_days}</TableCell>
              <TableCell>{`$${data.shipping_amount.amount}`}/ {`$${data.shipping_amount.amount + (DEFAULTS.RATEPERCENTAGE * data.shipping_amount.amount)}`}</TableCell>
              <TableCell align="right">{data.estimated_delivery_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </CardContent>
      </Card>
      </Container>
      </div>
    );
  }

  

  export default Reports;